import { httpAxiosV4, httpReport } from "../../../gateways/Axios";


export async function getCheckListEntry(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `checklist/entry`,
        "GET"
    );
    return data;
}

export async function getCheckListAnswer(checklist_uuid: string): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `checklist/answers?checklist_uuid=${checklist_uuid}`,
        "GET"
    );
    return data;
}

export async function getListTemplatesServer(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `checklist/templates`,
        "GET"
    );
    return data;
}

export async function createOrUpdateTemplate(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `checklist/templates`,
        "POST",
        payload
    );
    return data;
}

export async function getCategoriesByTemplateId(templateId: string): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `checklist/categories?template_id=${templateId}`,
        "GET"
    );
    return data;
}


export async function createOrUpdateCategory(categoryData: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `checklist/categories`,
        "POST",
        categoryData
    );
    return data;
}

export async function createOrUpdateItens(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `checklist/categories/itens`,
        "POST",
        payload
    );
    return data;
}


export async function getItemsByCategoryId(id: string): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `checklist/categories/itens?category_id=${id}`,
        "GET"
    );
    return data;
}



export async function deleteTemplate(templateId: string): Promise<void> {
    await httpAxiosV4<any, any>(
        `checklist/templates?template_id=${templateId}`,
        "DELETE"
    );
}

export async function deleteCategory(categoryId: string): Promise<void> {
    await httpAxiosV4<any, any>(
        `checklist/categories?category_id=${categoryId}`,
        "DELETE"
    );
}

export async function deleteItem(itemId: string): Promise<void> {
    await httpAxiosV4<any, any>(
        `checklist/categories/itens?item_id=${itemId}`,
        "DELETE"
    );
}


export async function getReportChecklist(uuid: any): Promise<any> {
    const { data } = await httpReport<any, any>(
        `report-checklist?checklist_uuid=${uuid}`,
        "GET",
        {},
        'blob'//important para gerar o pdf
    );
    return data;
}



export type iChecklistEntrySelect = {
    id: number;
    uuid: string;
    created_at: Date;
    updated_at: Date;
    total_itens: number;
    total_respondidos: number;
    template_descr: string;
    veiculo_placa: string | null;
    drivername: string | null;
    driveravatar: string | null;
};

export type iChecklistAnswer = {
    checklist_uuid: string;
    answer_uuid: string;
    category_id: number;
    category_name: string;
    category_description: string;
    question: string;
    input_type: 'boolean' | 'number' | 'text' | 'multipleselect' | 'select';
    response_options: string;
    requires_photo: number;
    response: string | null;
    observation: string | null;
    is_completed: number;
    photo_1_url: string | null;
    photo_2_url: string | null;
    photo_3_url: string | null;
    sync_version: number;
    created_at: string;
    updated_at: string | null;
};