import { httpAxiosV4, httpReport } from "../../../gateways/Axios";
import { iUser } from "../../../interfaces/interfaces";
import httpServerFiles from "../../../services/http-server-files";
import { dtFilter } from "../components/DateFilter";



export async function getExpensesByClient(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<reqExpenses, any>(
        `expenses-filter`,
        "POST",
        payload
    );
    return data;
}

export async function getExpenses(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<reqExpenses, any>(
        `Expenses-evt`,
        "POST",
        payload
    );
    return data;
}
export async function getRulesExpenses(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `Expenses-rules-client`,
        "GET"
    );
    return data;
}
export async function alterStatus(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses-alter-status`,
        "POST",
        payload
    );
    return data;
}
export async function deleteExpenses(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses`,
        "DELETE",
        payload
    );
    return data;
}
export async function alterVehicles(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses-alter-vehicles`,
        "POST",
        payload
    );
    return data;
}
export async function alterDrivers(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses-alter-drivers`,
        "POST",
        payload
    );
    return data;
}
export async function getVehiclesToExpense(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `vehicles/expenses`,
        "GET"
    );
    return data;
}
export async function getCategories(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses/categ?sync_version=0`,
        "GET"
    );
    return data;
}
export async function getFuel(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses/fuel?sync_version=0`,
        "GET"
    );
    return data;
}
export async function saveExpense(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses`,
        "POST",
        payload
    );
    return data;
}

export async function updateExpense(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses`,
        "PUT",
        payload
    );
    return data;
}

export async function getExpenseDashboad(payload: expensesDashboard): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses/dashboard`,
        "POST",
        payload
    );
    return data;
}


export const uploadImageExpense = async (file: File, user: iUser) => {
    if (!file) {
        console.log('Nenhuma imagem selecionada.');
        throw new Error('Nenhuma imagem selecionada.');
    }

    const formData = new FormData();
    formData.append('picture', file);
    formData.append('idclient', user.idcliente.toString());
    formData.append('idempresa', user.idempresa.toString());
    formData.append('idrecord', '0');
    formData.append('oldAvatar', '');

    httpServerFiles.post('/upload-expenses', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};




export async function getReportExpense(payload: any): Promise<any> {
    const { data } = await httpReport<any, any>(
        `expense/list`,
        "POST",
        payload,
        'blob'//important para gerar o pdf
    );
    return data;
}

export async function getReportOneExpense(payload: any): Promise<any> {
    const { data } = await httpReport<any, any>(
        `expense`,
        "POST",
        payload,
        'blob'//important para gerar o pdf
    );
    return data;
}




export interface reqExpenses {
    idclient: number
    idvehicle?: number
    iddriver?: number
    ExpenseId?: number
    cercaId?: number
    typeEvt?: string
    status?: string
    dtFilter?: dtFilter
}



export interface itemExpenses {
    id: number
    idclient: number;
    iddriver: number;
    idvehicle: number;
    idevt: number;
    id_Expense: number;
    idcerca: number;
    priority: number;
    points: number;
    tipo: string;
    evt: string;
    param1: string;
    avatar: string;
    title: string;
    descr: string;
    lat: string;
    lng: string;
    obs: string | null;
    create_at: string;
    dtevt_at: string;
    DriverAvatar: string;
    DriverName: string;
    vehicle: string;
    icone: string;
}

export interface itemRuleExpense {
    id: number;
    title: string;
    icon: string;
    type_Expense: string;
    Expensea_id: number;
    id_Expense_setup: number;
    iduser: number;
    idempresa: number;
    descr: string;
    tipo: string;
    param1: string;
    valor: null | number;
    notif_driver: null | string;
    notif_client: null | string;
    IsRangerWork: number;
    semana: string;
    priority: number;
    points: number;
    points_active: number;
    nExpenseas: number;
    intervalo: number;
    status: number;
    modo: number;
    nivel: number;
    sons: number;
    evento: number;
    inFavActive: number;
    inFavExpense: number;
    inFavTimer: number;
    inFavTol: number;
    outFavActive: number;
    outFavExpense: number;
    outFavTimer: number;
    outFavTol: number;
    delete_at: null | string;
    update_at: null | string;
    create_at: null | string;
    num_subscribes: number;
    num_cercas: number;
    num_vehicles: number;
};


export interface ExpenseItemScreen {
    id: number;
    unique_id: string;
    placa: string;
    descr: string;
    icon_svg: string;
    id_client: number;
    id_empresa: number;
    id_motorista: number;
    id_viagem: number;
    id_veiculo: number;
    fornecedor_uuid: string | null;
    supplier_name: string | null;
    id_categ: number;
    categories: string
    amount: number;
    expense_type: string;
    status: 'Pendente' | 'Aprovado' | 'Cancelado' | 'Em Análise' | 'Rejeitado' | 'Pago';
    lat: number;
    lng: number;
    photo1: string;
    photo2: string;
    photo3: string;
    km_start: number;
    km_end: number;
    km_vehicle: number;
    km_run: number;
    avg_fuel: number;
    qty: number | null;
    price_unity: number | null;
    obs: string | null;
    fuel_cod: number;
    fuel_descr: string | null;
    tipo_payment_id: number | null;
    sync_version: string;
    evt_at: string;
    create_at: string;
    update_at: string;
    delete_at: string | null;
    name: string | null;
    driveravatar: string;
    drivername: string;
};


export interface iVehicleExpense {
    idvehicle: number;
    manufacturer: string;
    model: string;
    placa: string;
    fuel_primary: number;
    km_last_abast: number;
    apelido: string;
    icon: string;
    odometer_vehicle: number;
}

export interface iFuelTypeExpense {
    fuel_cod: number;
    id: number;
    descr: string;
    color: string;
    norder: number;
}

export interface expensesDashboard {
    idClient: number,
    section: string,
    startDate: string,
    endDate: string
}

export interface iCategoryResume {
    category: string;
    category_icon: string;
    total_amount: number;
}


