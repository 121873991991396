


import React from 'react';
import { getListDrivers, itemDriver } from '../repository/driversRepository';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { ContainerInfo, ContainerSearch, ContainerTable, IconButton, InfoBox, SearchAndSelectContainer } from '../../StylePages';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import { getColumnsDrivers } from '../Utils/DriversColumns';
import { handleSearch } from '../../../utils/searchUtils';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { extractErrorMessage } from '../../../utils/CustomErros';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import NoData from '../../../components/NoData';
import { FilePenIcon, RefreshCcw } from 'lucide-react';


interface CompProps {
    openDetails: (item: itemDriver) => void
    cadEditDriver: (item: itemDriver) => void
    cadNewDriver: () => void
}

interface IOption {
    value: string;
    label: string;
}

const optionsActions = [
    { value: 'SETBLOCK', label: 'Bloquear Usuários' },
    { value: 'SETUNBLOCK', label: 'Liberar Usuários' },
    { value: 'DELETEUSER', label: 'Apagar Usuários' },

];

interface iAction {
    ids: number[];
    title: string;
    msg: string;
    action: string;
    value: any;
}

const ViewListDrivers: React.FC<CompProps> = ({ openDetails, cadNewDriver, cadEditDriver }) => {

    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<number>>(() => new Set());
    const [rows, setRows] = React.useState<itemDriver[]>([]);
    const [message, setMessage] = React.useState<string | undefined>('Carregando dados...');
    const { addNotification } = useNotifications();
    const [itemAction, setItemAction] = React.useState<iAction>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);




    const columns = getColumnsDrivers(cadEditDriver, openDetails);

    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);



    async function getDados() {
        try {
            setMessage('Carregando dados...');
            const result = await getListDrivers();
            setRows(result)
        } catch (e) {
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setMessage(undefined)
        }
    }

    React.useEffect(() => {
        getDados()
    }, [])


    const handleSelectAction = (action: string) => {
        if (selectedRows.size === 0) {
            addNotification('', 'Precisa selecionar ao menos um registro', 'warning');
            return;
        }

        switch (action) {
            case 'SETBLOCK':
                setItemAction({
                    ids: Array.from(selectedRows),
                    title: 'Bloquear Usuários',
                    msg: 'Deseja bloquear os usuários selecionados?',
                    action: 'SETBLOCK',
                    value: 0
                });
                break;
            case 'SETUNBLOCK':
                setItemAction({
                    ids: Array.from(selectedRows),
                    title: 'Liberar Usuários',
                    msg: 'Deseja liberar os usuários selecionados?',
                    action: 'SETUNBLOCK',
                    value: 1
                });
                break;
            case 'DELETEUSER':
                setItemAction({
                    ids: Array.from(selectedRows),
                    title: 'Apagar Usuários',
                    msg: 'Deseja apagar os usuários selecionados?',
                    action: 'DELETEUSER',
                    value: 0
                });
                break;
            default:
                break;
        }

    };


    const sendActionBlock = async (action: iAction) => {
        try {
            setIsLoading(true);
            //await blockUsersAlter(action.ids, action.value);
            addNotification('', 'Ação realizada com sucesso', 'success');
            getDados();
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const sendActionDelete = async (action: iAction) => {
        try {
            setIsLoading(true);
            //await deleteUsers(action.ids);
            addNotification('', 'Ação realizada com sucesso', 'success');
            getDados();
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const handleConfirmedAction = () => {
        setItemAction(undefined);
        if (itemAction === undefined) return;
        switch (itemAction.action) {
            case 'SETBLOCK':
            case 'SETUNBLOCK':
                sendActionBlock(itemAction);
                break;
            case 'DELETEUSER':
                sendActionDelete(itemAction);
                break;
            default:
                break;
        }

    }

    return (
        <>
            {message !== undefined ? <LoadingIndicator message={message} /> :
                <>
                    <SearchAndSelectContainer>
                        {/*<ActionSelect
                            options={optionsActions}
                            onSelect={handleSelectAction}
                            title='Selecione a ação'
                        />*/}
                        <ContainerSearch>
                            <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                        </ContainerSearch>
                        <IconButton onClick={getDados}>
                            <RefreshCcw size={20} />
                        </IconButton>
                        <IconButton onClick={cadNewDriver}>
                            <FilePenIcon size={20} />
                            <span>Novo</span>
                        </IconButton>

                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                    </ContainerInfo>
                    {filteredRows.length === 0 ? <NoData message="Nenhum Registro Encontrado" />
                        : (<>
                            <ContainerTable>
                                <DataGridComp
                                    rows={filteredRows}
                                    columns={columns}
                                    selectedRows={selectedRows}
                                    onRowSelect={setSelectedRows}
                                />
                            </ContainerTable>
                        </>)}

                </>
            }
        </>
    )

}
export default ViewListDrivers

