import httpApiMessenger from '../../../services/http-messenger';
import httpApiFinance from '../../../services/http-xsmart-finance';




export async function getListInvoices(payload: any): Promise<itemCobrancas[]> {
    const result = await httpApiFinance.post('/listInvoices', payload);
    return result.data;
}

export async function checkConnectionApi(idempresa: number): Promise<any> {
    const result = await httpApiMessenger.get(`instance/status?idempresa=${idempresa}`);
    return result.data;
}

export async function disconectWhatsappApi(idempresa: number): Promise<any> {
    const result = await httpApiMessenger.get(`instance/logout?idempresa=${idempresa}`);
    return result.data;
}

export async function connectWhatsappApi(idempresa: number): Promise<any> {
    const result = await httpApiMessenger.get(`instance/connect?idempresa=${idempresa}`);
    return result.data;
}

export async function createInstanceWhatsapp(payload: any): Promise<any> {
    const result = await httpApiMessenger.post(`/instance/create`, payload);
    return result.data;
}

export async function generateTokenWpp(idempresa: number, session: string): Promise<any> {
    const result = await httpApiMessenger.post(`generate-token`, { idempresa: idempresa, session: session });
    return result.data;
}

export async function startSessionWpp(idempresa: number): Promise<any> {
    const result = await httpApiMessenger.post(`start-session`, { idempresa: idempresa });
    return result.data;
}

export async function logoutSessionWpp(idempresa: number): Promise<any> {
    const result = await httpApiMessenger.post(`logout-session`, { idempresa: idempresa });
    return result.data;
}


export type itemCobrancas = {
    id: number
    idcliente: number
    cliente_nome: string
    descr: string
    status_system: string
    boletoid: number
    pix_txid: string
    code: number
    amount: number
    amountPay: number
    dt_venc: string
    pago: number
    pay_type: string
    dt_emissao: string
    dt_pay: string
    num_days: number
    next_alert: number
    next_block: number
}