import React from 'react';
import styled from 'styled-components';
import { X } from 'lucide-react';

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 0.75rem;
`;

const FilterBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #e5e7eb;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  color: #374151;
`;

const TotalBadge = styled(FilterBadge)`
  background-color: #dbeafe;
  color: #1e40af;
`;

const ClearButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.125rem;
  color: #6b7280;
  border-radius: 9999px;
  
  &:hover {
    color: #374151;
    background-color: #d1d5db;
  }
`;

interface ActiveFiltersProps {
    date?: Date;
    limit: number;
    totalFiles: number;
    onClearDate: () => void;
    onClearLimit: () => void;
}

export const ActiveFilters: React.FC<ActiveFiltersProps> = ({
    date,
    limit,
    totalFiles,
    onClearDate,
    onClearLimit,
}) => {
    if (!date && limit === 50 && !totalFiles) return null;

    return (
        <Container>
            {date && (
                <FilterBadge>
                    <span>Antes de: {date.toLocaleDateString('pt-BR')}</span>
                    <ClearButton onClick={onClearDate}>
                        <X size={14} />
                    </ClearButton>
                </FilterBadge>
            )}
            {limit !== 50 && (
                <FilterBadge>
                    <span>Limite: {limit} arquivos</span>
                    <ClearButton onClick={onClearLimit}>
                        <X size={14} />
                    </ClearButton>
                </FilterBadge>
            )}
            {totalFiles !== undefined && (
                <TotalBadge>
                    <span>Total: {totalFiles} de {limit} arquivos</span>
                </TotalBadge>
            )}
        </Container>
    );
}