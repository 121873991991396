import React, { } from 'react';
import WhatsAppConnection from '../components/WhatsAppConnection';

const WhatsAppPage: React.FC = () => {
    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
            <WhatsAppConnection />
        </div>
    );
}

export default WhatsAppPage;
