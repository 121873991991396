import { Column, SelectColumn } from 'react-data-grid';
import { itemCobrancas } from '../repository/FinanceGateway';
import { FormatterCurrency } from '../../../utils/format';
import { getDateView } from '../../../utils/Converter';
import { styled } from 'styled-components';


const StatusPay = styled.div<{ value: number, num_days: number }>`
    color: ${p => p.value === 0 ? p.num_days <= 0 ? '#0b0fee' : '#ff0000cc' : '#08b611'};
    font-weight: 500;
    display: flex;
    align-items: center ;
    justify-content:center;
`
const getStatus = (item: itemCobrancas) => {
    if (item.pago) return `PAGO ${item.pay_type ?? ''}`
    let resp = item.num_days === 0 ? 'VENCE HOJE' : item.num_days > 0 ? `ATRASADO À ${item.num_days} D` : `VENCE EM ${item.num_days * -1} D`
    return resp
}


export const getInvoicesColumns = (
): Column<itemCobrancas>[] => {
    const columns: Column<itemCobrancas>[] = [
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        {
            key: 'id',
            name: "Código",
            maxWidth: 60
        },
        {
            key: 'pago',
            name: "Status",
            resizable: true,
            minWidth: 100,
            renderCell(props: any) {
                const info = props.row.pago ? 'PAGO' : 'ABERTO' + props.row.num_days;
                return (
                    <>
                        <StatusPay value={props.row.pago} num_days={props.row.num_days}>{getStatus(props.row)}</StatusPay>
                    </>
                )
            }
        },
        {
            key: 'cliente_nome',
            name: "Cliente",
            resizable: true,
            width: 250,
        },
        {
            key: 'descr',
            name: "Descrição",
            resizable: true,
            width: 200,
        },
        {
            key: 'amount',
            name: "Valor",
            renderCell(props: any) {
                return FormatterCurrency(props.row.amount);
            }


        },
        {
            key: 'dt_venc',
            name: "Vencimento",
            renderCell(props: any) {
                return getDateView(props.row.dt_venc);
            }
        },
        {
            key: 'pix_txid',
            name: "Chave",
            resizable: true,
            width: 200,
        },
        {
            key: 'boletoid',
            name: "Cód Boleto"
        },

    ];

    return columns;
};

