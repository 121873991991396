import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Search, X, ChevronDown } from 'lucide-react';
import { Vehicle } from '../types';

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
`;

const SelectButton = styled.button`
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: border-color 0.2s;

  &:hover {
    border-color: #9ca3af;
  }
`;

const SearchWrapper = styled.div`
  position: relative;
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.5rem 2.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  outline: none;
  
  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 0.75rem;
  color: #6b7280;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 0.75rem;
  padding: 0.25rem;
  color: #6b7280;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: #f3f4f6;
    color: #374151;
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 100%;
  margin-top: 0.25rem;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
`;

interface DropdownItemProps {
  isSelected: boolean;
}

const DropdownItem = styled.li<DropdownItemProps>`
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.isSelected ? '#f3f4f6' : 'transparent'};
  
  &:hover {
    background-color: ${props => props.isSelected ? '#e5e7eb' : '#f3f4f6'};
  }

  span.vehicle-type {
    font-size: 0.875rem;
    color: #6b7280;
  }
`;

interface VehicleSelectProps {
  onVehicleSelect: (vehicle: Vehicle) => void;
  vehicles: Vehicle[];
}

export const VehicleSelect: React.FC<VehicleSelectProps> = ({ onVehicleSelect, vehicles }) => {
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);

  const filteredVehicles = useMemo(() => {
    return vehicles.filter(vehicle =>
      vehicle.apelido.toLowerCase().includes(search.toLowerCase()) ||
      vehicle.imei.includes(search)
    );
  }, [vehicles, search]);

  useEffect(() => {
    if (vehicles.length > 0) {
      const firstVehicle = vehicles[0];
      setSelectedVehicle(firstVehicle);
      onVehicleSelect(firstVehicle);
    }
  }, [vehicles, onVehicleSelect]);

  const handleSelect = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle);
    onVehicleSelect(vehicle);
    setSearch('');
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSearch('');
  };

  return (
    <SelectContainer>
      {!isOpen ? (
        <SelectButton onClick={() => setIsOpen(true)}>
          <span>{selectedVehicle ? selectedVehicle.apelido : 'Selecionar veículo...'}</span>
          <ChevronDown size={20} color="#6b7280" />
        </SelectButton>
      ) : (
        <SearchWrapper>
          <SearchContainer>
            <IconWrapper>
              <Search size={20} />
            </IconWrapper>
            <SearchInput
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Pesquisar veículo..."
              autoFocus
            />
            <CloseButton onClick={handleClose}>
              <X size={20} />
            </CloseButton>
          </SearchContainer>

          {filteredVehicles.length > 0 && (
            <DropdownList>
              {filteredVehicles.map((vehicle) => (
                <DropdownItem
                  key={vehicle.imei}
                  isSelected={selectedVehicle?.imei === vehicle.imei}
                  onClick={() => handleSelect(vehicle)}
                >
                  <span>{vehicle.apelido}</span>
                  <span className="vehicle-type">{vehicle.tipo}</span>
                </DropdownItem>
              ))}
            </DropdownList>
          )}
        </SearchWrapper>
      )}
    </SelectContainer>
  );
};