import { httpReport } from "../../../gateways/Axios";
import { iSummaryData } from "../../MapAdvance/repository/MapsRepo";

export function openReportInNewAba(data: any) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
    // Liberar a URL do Blob após alguns segundos para evitar acúmulo de URLs temporárias
    // setTimeout(() => {
    //     window.URL.revokeObjectURL(url);
    // }, 500);

    //removido pq não consegue baixar o arquivo
}

export function openReportInNewAbaExcel(data: any) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
    // Liberar a URL do Blob após alguns segundos para evitar acúmulo de URLs temporárias
    // setTimeout(() => {
    //     window.URL.revokeObjectURL(url);
    // }, 500);
}

export async function getReportStops(payload: any): Promise<any> {
    const { data } = await httpReport<any, any>(
        `report-stops`,
        "POST",
        payload,
        payload.mode === 'view' ? 'json' : 'blob'//important para gerar o pdf
    );
    return data;
}
export async function getReportHistory(payload: any): Promise<any> {
    const { data } = await httpReport<any, any>(
        `report-positions`,
        "POST",
        payload,
        payload.mode === 'view' ? 'json' : 'blob'//important para gerar o pdf
    );
    return data;
}

export async function getReportHistoryToMap(payload: any): Promise<iSummaryData> {
    const { data } = await httpReport<any, any>(
        `report-positions-map`,
        "POST",
        payload,
        'json'
    );
    return data;
}

export async function getReportEvents(payload: any): Promise<any> {
    const { data } = await httpReport<any, any>(
        `events`,
        "POST",
        payload,
        payload.mode === 'view' ? 'json' : 'blob'//important para gerar o pdf
    );
    return data;
}

export async function getReportSummary(payload: any): Promise<any> {
    const { data } = await httpReport<any, any>(
        `vehicle/summary`,
        "POST",
        payload,
        payload.mode === 'view' ? 'json' : 'blob'//important para gerar o pdf
    );
    return data;
}


export async function getReportRouter(payload: any): Promise<any> {
    const { data } = await httpReport<any, any>(
        `delivery/route`,
        "POST",
        payload,
        payload.mode === 'view' ? 'json' : 'blob'//important para gerar o pdf
    );
    return data;
}