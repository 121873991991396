import React from 'react';
import { selectUser } from '../../../redux/userSlice';
import { useSelector } from 'react-redux';
import { getEvents, iEventReport, itemReqEvents } from '../repository/StreamRepo';
import { ContainerTable, SearchAndSelectContainer } from '../Styles/StyleStreamCam';
import DateFilterEvents, { dtFilter } from '../Components/DateFilterEvents';
import { useNotifications } from '../../../Notifications/NotificationContext';

//datagrid
import { Column, SelectColumn } from 'react-data-grid';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { FaCamera, FaEye, FaVideo } from 'react-icons/fa';
import StreamH264Player from '../Components/StreamH264Player';
import DialogStreamEvt from '../Components/DialogStreamEvt';
import { format } from 'date-fns';
import { MediaTableEvents } from '../Components/MediaTableEvents';
import { Modal } from '../Components/Modal';
import { styled } from 'styled-components';


const EventsPage: React.FC = () => {

    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);
    const [dtFilter, setDtFilter] = React.useState<dtFilter>({ lastreg: 1000 });

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<iEventReport[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<iEventReport[]>(rows);
    const [columnsFilter, setColumnsFilter] = React.useState<Column<iEventReport>[]>([]);
    const [visibleColumns, setVisibleColumns] = React.useState<string[]>(columnsFilter.map(column => column.key.toString()));
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [videoFile, setVideoFile] = React.useState<string>();


    React.useEffect(() => {
        if (videoFile !== '') {
            setOpenModal(true)
        }
    }, [videoFile])

    async function getDados() {
        try {
            const payload: itemReqEvents = {
                lastregister: 0,
                idclient: user.idcliente,
                idVehicle: 0,
                dtStart: dtFilter.dtStart ?? new Date().toISOString(),
                dtEnd: dtFilter.dtEnd ?? new Date().toISOString()
            }
            const result = await getEvents(payload);
            setRows(result)
            setFilteredRows(result);
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }

    }

    React.useEffect(() => {
        let isInitEvent = true
        console.log("EventsPage", isInitEvent)
        if (isInitEvent) {
            isInitEvent = false;
            getDados()
        }
    }, [dtFilter])

    const handleClickRow = (row: iEventReport) => {
        // console.log(row)
    }

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    const handleOpenVideo = (event: iEventReport, file: string) => {

        const fileName = event.tipo === 'JC4xx' ? file : `${event.imei}_${file}_1_00.mp4`;
        const fileLink = `${process.env.REACT_APP_FILES_RTMP}/view-file/${event.imei}/${fileName}`;
        setVideoFile(fileLink);
        setOpenModal(true);
    }


    return (
        <>
            <SearchAndSelectContainer>
                <DateFilterEvents onDateChange={setDtFilter} />
            </SearchAndSelectContainer>
            <MediaTableEvents events={filteredRows} onPlayer={handleOpenVideo} />
            <Modal
                isOpen={!!videoFile}
                onClose={() => setVideoFile(undefined)}
            >

                <MediaPreview>
                    <video controls={true} autoPlay={true}>
                        <source
                            src={`${videoFile}`}
                            type="video/mp4"

                        />
                    </video>
                    <p>{videoFile}</p>
                </MediaPreview>

            </Modal>
        </>
    )

}
export default EventsPage

const MediaPreview = styled.div`
  max-width: 80vw;
  max-height: 80vh;
  
  img, video {
    max-width: 100%;
    max-height: 75vh;
    object-fit: contain;
  }
`;