import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Edit, Plus, RotateCw, Trash2 } from 'lucide-react';
import { Button } from '../components/Button';
import { Modal } from '../components/Modal';
import { Input } from '../components/Input';
import { Loading } from '../components/Loading';
import { ChecklistTemplate } from '../types/checklist';
import TemplateDetails from './TemplateDetails';
import { createOrUpdateTemplate, deleteTemplate, getListTemplatesServer } from '../Repository/CheckListRepo';
import { set } from 'date-fns';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  min-height: 100vh;
  background-color: #f3f4f6;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
`;

const HeaderActions = styled.div`
  display: flex;
  gap: 1rem;
`;

const TemplateList = styled.div`
  display: grid;
  gap: 1rem;
`;

const TemplateCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  border: 1px solid transparent;
  
  cursor: pointer;

  &:hover {
    border-color: #e5e7eb;
    background-color: #f9fafb;
  }
`;

const TemplateInfo = styled.div`
  flex: 1;
`;

const TemplateName = styled.h3`
  font-size: 1.125rem;
  font-weight: 500;
  color: #1f2937;
  margin-bottom: 0.25rem;
`;

const TemplateDescription = styled.p`
  font-size: 0.875rem;
  color: #6b7280;
`;

const TemplateUuid = styled.p`
  font-size: 0.875rem;
  color: #6b7280;
`;

const TemplateActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

function TemplateListPage() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templates, setTemplates] = useState<ChecklistTemplate[]>([]);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newTemplateDescription, setNewTemplateDescription] = useState('');
  const [editingTemplate, setEditingTemplate] = useState<ChecklistTemplate | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [templateDetails, setTemplateDetails] = useState<ChecklistTemplate>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<ChecklistTemplate | null>(null);


  const loadTemplates = async () => {
    try {
      setIsLoading(true);
      const data = await getListTemplatesServer();
      setTemplates(data);
    } catch (error) {
      console.error('Erro ao carregar templates:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  const handleAddTemplate = async () => {
    if (!newTemplateName.trim()) return;

    try {
      setIsLoading(true);
      const data = await createOrUpdateTemplate({
        name: newTemplateName,
      });
      loadTemplates();
      setIsModalOpen(false);
      setNewTemplateName('');
      setNewTemplateDescription('');
      setEditingTemplate(null);
    } catch (error) {
      console.error('Erro ao carregar templates:', error);
    } finally {
      setIsLoading(false);
    }

  };

  const handleEditTemplate = (template: ChecklistTemplate) => {
    setEditingTemplate(template);
    setNewTemplateName(template.name);
    setNewTemplateDescription(template.description || '');
    setIsModalOpen(true);
  };

  const handleSaveEdit = async () => {
    if (!editingTemplate || !newTemplateName.trim()) return;


    try {
      setIsLoading(true);
      const data = await createOrUpdateTemplate({
        name: newTemplateName,
        description: newTemplateDescription || '',
        uuid: editingTemplate.uuid
      });
      loadTemplates();
      setIsModalOpen(false);
      setNewTemplateName('');
      setNewTemplateDescription('');
      setEditingTemplate(null);
    } catch (error) {
      console.error('Erro ao carregar templates:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewTemplateName('');
    setNewTemplateDescription('');
    setEditingTemplate(null);
  };

  const handleDeleteTemplate = (item: ChecklistTemplate) => {
    setTemplateToDelete(item);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteTemplate = async () => {
    if (!templateToDelete) return;
    await deleteTemplate(templateToDelete.uuid);
    loadTemplates();
    setTemplateToDelete(null);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      {!templateDetails ?
        <Container>
          <Header>
            <Title>Modelos de Checklist</Title>
            <HeaderActions>
              <Button className="secondary" onClick={loadTemplates} disabled={isLoading}>
                <RotateCw size={20} className={isLoading ? 'animate-spin' : ''} />
                Atualizar
              </Button>
              <Button className="secondary" onClick={() => setIsModalOpen(true)}>
                <Plus size={20} />
                Adicionar Modelo
              </Button>
            </HeaderActions>
          </Header>

          <TemplateList>
            {templates.map(template => (
              <TemplateCard key={template.id} onClick={() => setTemplateDetails(template)}>
                <TemplateInfo>
                  <TemplateName>{template.name}</TemplateName>
                  <TemplateDescription>{template.description}</TemplateDescription>
                  <TemplateUuid>Cód: {template.id} / {template.uuid}</TemplateUuid>
                </TemplateInfo>
                <TemplateActions onClick={(e) => e.stopPropagation()}>
                  <Button className="icon secondary" onClick={() => handleEditTemplate(template)}>
                    <Edit size={16} />
                  </Button>
                  <Button className="icon secondary" onClick={() => handleDeleteTemplate(template)}>
                    <Trash2 size={16} />
                  </Button>
                </TemplateActions>
              </TemplateCard>
            ))}
          </TemplateList>

          <Modal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            title={editingTemplate ? "Editar Modelo" : "Adicionar Novo Modelo"}
          >
            <Input
              type="text"
              placeholder="Nome do modelo"
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
            />
            <br />
            <br />
            <Input
              type="text"
              placeholder="Descrição"
              value={newTemplateDescription}
              onChange={(e) => setNewTemplateDescription(e.target.value)}
            />
            <div style={{ marginTop: '1rem', display: 'flex', gap: '0.5rem', justifyContent: 'flex-end' }}>
              <Button className="secondary" onClick={handleModalClose}>
                Cancelar
              </Button>
              <Button
                className="secondary"
                onClick={editingTemplate ? handleSaveEdit : handleAddTemplate}
              >
                {editingTemplate ? 'Salvar Alterações' : 'Salvar'}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => {
              setIsDeleteModalOpen(false);
              setTemplateToDelete(null);
            }}
            title={`Confirmar Exclusão do Template`}
          >
            <p className="mb-4">
              {`Tem certeza que deseja excluir o template "${templateToDelete?.name}" e todos os seus itens?`}

            </p>
            <div style={{ marginTop: '1rem', display: 'flex', gap: '0.5rem', justifyContent: 'flex-end' }}>
              <Button
                className="secondary"
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  setTemplateToDelete(null);
                }}
              >
                Cancelar
              </Button>
              <Button
                className="secondary"
                onClick={confirmDeleteTemplate}
              >
                Excluir
              </Button>
            </div>
          </Modal>
        </Container>
        : <TemplateDetails template={templateDetails} onClose={() => setTemplateDetails(undefined)} />}
    </>
  );
}

export default TemplateListPage;