import React from 'react';
import styled from 'styled-components';
import { Download, Share2, ExternalLink, Play, Image as ImageIcon, Maximize2 } from 'lucide-react';
import { MediaFile } from '../types';
import { formatFileSize } from '../../../utils/format';
import { iEventReport } from '../repository/StreamRepo';
import { getEventDescription } from '../Utils/UtilsStream';

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Th = styled.th`
  text-align: left;
  padding: 1rem;
  background: #f3f4f6;
  color: #374151;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  
  &:first-child {
    padding-left: 1.5rem;
  }
  
  &:last-child {
    padding-right: 1.5rem;
  }
`;

const Td = styled.td`
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
  color: #374151;
  
  &:first-child {
    padding-left: 1.5rem;
  }
  
  &:last-child {
    padding-right: 1.5rem;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  
  button, a {
    padding: 0.5rem;
    color: #4b5563;
    transition: color 0.2s;
    
    &:hover {
      color: #111827;
    }
  }
`;

interface MediaTableProps {
  events: iEventReport[];
  onPlayer: (event: iEventReport, file: string) => void;
}

const EVENTOS_PERMITIDOS = [
  "SHRP_ACCEL",
  "SHRP_DECEL",
  "SHRP_TURN",
  "OVRSPD_ALM",
  "FATIG_ALM",
  "SOS_ALM",
  "LOW_PWR",
  "AGA_SMOKING",
  "AGA_USING_PHONE",
  "AGA_YAWNING",
  "AGA_LANE_DRIFT",
  "AGA_FOLLOWING_CLOSE",
  "AGA_PED_COLLISION",
  "AGA_DISTRACTED",
  "AGA_ABRUPT_TURN",
  "DB_SPEEDING",
  "DB_HARD_ACCEL",
  "DB_LEFT_TURN",
  "DB_RIGHT_TURN",
  "DB_FATIGUE_DRIVE",
  "DB_ABRUPT_TURN",
  "DB_SPEED_WARN"
];

export const MediaTableEvents: React.FC<MediaTableProps> = ({ events, onPlayer }) => {

  return (
    <Table>
      <thead>
        <tr>
          <Th>Código</Th>
          <Th>Evento</Th>
          <Th>Veiculo</Th>
          <Th>Motorista</Th>
          <Th>Data/Hora</Th>
          <Th>Vídeos</Th>
        </tr>
      </thead>
      <tbody>
        {events
          //.filter((event) => EVENTOS_PERMITIDOS.includes(event.sevento)) // Apenas eventos da lista serão exibidos
          .map((event) => {
            const creationDate = new Date(event.devento);
            let listPlayer = event.tipo === "JC4xx" ? event.files_stream.split(",") : [event.files_stream];

            return (
              <tr key={event.id}>
                <Td>{event.id}</Td>
                <Td>{getEventDescription(event.sevento)}</Td>
                <Td>{event.apelido}</Td>
                <Td>{event.driver_name}</Td>
                <Td>{creationDate.toLocaleDateString()} {creationDate.toLocaleTimeString()}</Td>
                <Td>
                  <Actions>
                    {listPlayer.map((file, index) => (
                      <button key={index} onClick={() => onPlayer(event, file)}>
                        <Play size={16} />
                      </button>
                    ))}
                  </Actions>
                </Td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};