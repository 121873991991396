import React from 'react';
import styled from 'styled-components';
import { Download, Share2, ExternalLink, Play, Image as ImageIcon, Maximize2 } from 'lucide-react';
import { MediaFile } from '../types';
import { formatFileSize } from '../../../utils/format';

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Th = styled.th`
  text-align: left;
  padding: 1rem;
  background: #f3f4f6;
  color: #374151;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  
  &:first-child {
    padding-left: 1.5rem;
  }
  
  &:last-child {
    padding-right: 1.5rem;
  }
`;

const Td = styled.td`
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
  color: #374151;
  
  &:first-child {
    padding-left: 1.5rem;
  }
  
  &:last-child {
    padding-right: 1.5rem;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  
  button, a {
    padding: 0.5rem;
    color: #4b5563;
    transition: color 0.2s;
    
    &:hover {
      color: #111827;
    }
  }
`;

interface MediaTableProps {
  files: MediaFile[];
  imei: string;
  onExpand: (file: MediaFile) => void;
}

export const MediaTable: React.FC<MediaTableProps> = ({ files, imei, onExpand }) => {

  const handleWhatsAppShare = (fileUrl: string) => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(fileUrl)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Table>
      <thead>
        <tr>
          <Th>Tipo</Th>
          <Th>Nome</Th>
          <Th>Data</Th>
          <Th>Tamanho</Th>
          <Th>Ações</Th>
        </tr>
      </thead>
      <tbody>
        {files.map((file) => {
          const isVideo = file.nome.endsWith('.mp4');
          const fileUrl = `${process.env.REACT_APP_FILES_RTMP}/view-file/${imei}/${file.nome}`;
          const fileUrlDownload = `${process.env.REACT_APP_FILES_RTMP}/download-video/${imei}/${file.nome}`;
          const creationDate = new Date(file.dataModificacao);

          return (
            <tr key={file.nome}>
              <Td>
                {isVideo ? (
                  <Play size={20} />
                ) : (
                  <ImageIcon size={20} />
                )}
              </Td>
              <Td>{file.nome}</Td>
              <Td>{creationDate.toLocaleDateString()} {creationDate.toLocaleTimeString()}</Td>
              <Td>{formatFileSize(file.tamanho)}</Td>
              <Td>
                <Actions>
                  <button onClick={() => onExpand(file)} title="Expandir">
                    <Maximize2 size={20} />
                  </button>
                  <button
                    onClick={() => {
                      handleWhatsAppShare(fileUrl);
                    }}
                    title="Compartilhar"
                  >
                    <Share2 size={20} />
                  </button>
                  <button
                    onClick={() => {
                      window.open(fileUrlDownload, '_blank');
                    }}
                    title="Baixar"
                  >
                    <Download size={20} />
                  </button>

                </Actions>
              </Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};