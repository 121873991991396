import styled, { keyframes } from 'styled-components';

const progressAnimation = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`;

export const ConnectionCard = styled.div`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 24px;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const IconWrapper = styled.div`
  background-color: #25D366;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  color: white;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

export const StatusIndicator = styled.div<{ connected: boolean }>`
  display: flex;
  align-items: center;
  margin-left: auto;
  
  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => props.connected ? '#25D366' : '#ff4d4f'};
    margin-right: 6px;
  }
`;

export const StatusText = styled.span`
  font-size: 14px;
  color: #666;
`;

export const ProgressContainer = styled.div`
  height: 4px;
  background-color: #f0f0f0;
  border-radius: 2px;
  margin: 16px 0;
  overflow: hidden;
`;

export const ProgressBar = styled.div<{ duration: number }>`
  height: 100%;
  background-color: #25D366;
  border-radius: 2px;
  animation: ${progressAnimation} ${props => props.duration}s linear;
`;

export const Button = styled.button`
  background-color: #25D366;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #128C7E;
  }
  
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

export const ConfigForm = styled.form`
  margin-top: 20px;
`;

export const FormGroup = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  color: #333;
  margin-bottom: 6px;
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  color: #333;
  background-color: white;
  
  &:focus {
    outline: none;
    border-color: #25D366;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  color: #333;
  
  &:focus {
    outline: none;
    border-color: #25D366;
  }
`;

export const ErrorMessage = styled.p`
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  text-align: center;
`;

export const QRCodeContainer = styled.div`
  margin: 20px 0;
  
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const ModalTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
`;

export const ModalText = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
  text-align: center;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 20px;
`;