import React from 'react';
import { User, Truck, CheckSquare } from 'lucide-react';
import styled from 'styled-components';
import { iChecklistEntrySelect } from '../Repository/CheckListRepo';


interface ChecklistCardProps {
    checklist: iChecklistEntrySelect;
}

const Card = styled.div`
  background: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;
  max-width: 450px;
  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const Title = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2937;
  flex: 1;
`;

const DateInfo = styled.div`
  text-align: right;
`;

const DateText = styled.div`
  font-size: 0.875rem;
  color: #6b7280;
`;

const ProgressSection = styled.div`
  margin-bottom: 1rem;
`;

const ProgressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
`;

const ProgressText = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: #e5e7eb;
  border-radius: 9999px;
`;

const ProgressBar = styled.div<{ width: number }>`
  height: 100%;
  background-color: #22c55e;
  border-radius: 9999px;
  transition: width 0.3s;
  width: ${props => props.width}%;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  color: #374151;
`;

const IconWrapper = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  font-size: 0.875rem;
`;

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StatusIndicator = styled.div`
  display: flex;
  align-items: center;
`;

const StatusDot = styled.div<{ status: 'completed' | 'inProgress' | 'notStarted' }>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: ${props => {
        switch (props.status) {
            case 'completed':
                return '#22c55e';
            case 'inProgress':
                return '#eab308';
            case 'notStarted':
                return '#ef4444';
            default:
                return '#6b7280';
        }
    }};
`;

const StatusText = styled.span`
  font-size: 0.875rem;
  color: #4b5563;
`;

const CompletedItems = styled.div`
  display: flex;
  align-items: center;
  color: #374151;
`;

export const ChecklistCard: React.FC<ChecklistCardProps> = ({ checklist }) => {
    const completionPercentage = (checklist.total_respondidos / checklist.total_itens) * 100;
    const formattedDate = new Date(checklist.created_at).toLocaleDateString('pt-BR');
    const formattedUpdateDate = new Date(checklist.updated_at).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });

    const getStatus = () => {
        if (completionPercentage === 100) return 'completed';
        if (completionPercentage > 0) return 'inProgress';
        return 'notStarted';
    };

    const getStatusText = () => {
        if (completionPercentage === 100) return 'Concluído';
        if (completionPercentage > 0) return 'Em Andamento';
        return 'Não Iniciado';
    };

    return (
        <Card>
            <Header>
                <Title>{checklist.template_descr}</Title>
                <DateInfo>
                    <DateText>Criado: {formattedDate}</DateText>
                    <DateText>Atualizado: {formattedUpdateDate}</DateText>
                </DateInfo>
            </Header>

            <ProgressSection>
                <ProgressHeader>
                    <ProgressText>
                        Progresso: {checklist.total_respondidos}/{checklist.total_itens}
                    </ProgressText>
                    <ProgressText>
                        {completionPercentage.toFixed(0)}%
                    </ProgressText>
                </ProgressHeader>
                <ProgressBarContainer>
                    <ProgressBar width={completionPercentage} />
                </ProgressBarContainer>
            </ProgressSection>

            <InfoRow>
                <InfoItem>
                    <IconWrapper>
                        <User size={18} />
                    </IconWrapper>
                    <Text>
                        {checklist.drivername || 'Motorista não atribuído'}
                    </Text>
                </InfoItem>
                <InfoItem>
                    <IconWrapper>
                        <Truck size={18} />
                    </IconWrapper>
                    <Text>
                        {checklist.veiculo_placa || 'Veículo não atribuído'}
                    </Text>
                </InfoItem>
            </InfoRow>

            <StatusRow>
                <StatusIndicator>
                    <StatusDot status={getStatus()} />
                    <StatusText>{getStatusText()}</StatusText>
                </StatusIndicator>
                <CompletedItems>
                    <IconWrapper>
                        <CheckSquare size={18} />
                    </IconWrapper>
                    <Text>{checklist.total_respondidos} itens completados</Text>
                </CompletedItems>
            </StatusRow>
        </Card>
    );
};