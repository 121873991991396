

import { httpAxiosV4 } from "../../../gateways/Axios";




export async function getListContracts(idempresa: number): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `contracts/list?company_id=${idempresa}`,
        "GET"
    );
    return response;
}


export async function getListClients(): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `clients/list-select`,
        "GET"
    );
    return response;
}

export async function saveContractInClients(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `contract/save-in-clients`,
        "POST",
        payload
    );
    return response;
}



export type iContractType = {
    id: number;
    contract_id: string;
    template_id: number;
    signed_contract_link: string | null;
    latitude: string;
    longitude: string;
    selfie_photo: string;
    document_photo: string;
    signature_photo: string;
    user_id: number;
    company_id: number;
    client_id: number;
    client_name: string;
    status_contract: "ASSIGN" | "PENDING" | "COMPLETED" | "CANCELED"; // Exemplos de status possíveis
    start_date: string | null; // ISO 8601 format
    end_date: string | null; // ISO 8601 format
    contract_months: number;
    created_at: string; // ISO 8601 format
    updated_at: string; // ISO 8601 format
    deleted_at: string | null; // ISO 8601 format
    signed_at: string | null; // ISO 8601 format
};
