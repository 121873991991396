import React, { useState } from 'react';
import {
  ConfigForm,
  FormGroup,
  Label,
  Select,
  Input,
  Button,
  ErrorMessage,
  ButtonGroup
} from './styles';
import QRCodeModal from './QRCodeModal';
import { createInstanceWhatsapp } from '../repository/FinanceGateway';
import { AuthConfig } from '../../../Config/AuthContext';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { extractErrorMessage } from '../../../utils/CustomErros';

interface ConfigurationFormProps {
  onStatusChange: (status: 'connected' | 'disconnected' | 'qrcode') => void;
  onFormVisibilityChange: (show: boolean) => void;
}

const ConfigurationForm: React.FC<ConfigurationFormProps> = ({
  onStatusChange,
  onFormVisibilityChange
}) => {
  const { user } = AuthConfig();
  const { addNotification } = useNotifications();
  const [whatsappType, setWhatsappType] = useState<string>('WHATSAPP-BAILEYS');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [error, setError] = useState<string>('');

  const validatePhoneNumber = (number: string): boolean => {
    // Basic validation for phone number (can be enhanced based on requirements)
    const phoneRegex = /^\d{10,15}$/;
    return phoneRegex.test(number);
  };

  // API call to connect to WhatsApp
  const connectToWhatsApp = async (type: string, phoneNumber: string): Promise<void> => {
    console.log(`Connecting to ${type} with phone number ${phoneNumber}`);
    if (!user) {
      console.error('User not found');
      addNotification('', 'Usuário não encontrado', 'error');
      return;
    }
    try {
      const respone = await createInstanceWhatsapp({
        instanceName: `${user?.idempresa}`,
        number: phoneNumber,
        integration: type
      });
      console.log('Response:', respone);
      handleCancel();

    }
    catch (error) {
      console.error('Error checking connection', error);
      let message = extractErrorMessage(error)
      addNotification('', message, 'error');
    }

  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!phoneNumber) {
      setError('Número de telefone é obrigatório');
      return;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      setError('Número de telefone inválido. Use apenas números (10-15 dígitos)');
      return;
    }

    setError('');

    try {
      const response = await connectToWhatsApp(whatsappType, phoneNumber);
      console.log('ConnectResponse:', response);

    } catch (error) {
      console.error('Error connecting to WhatsApp:', error);
      setError('Erro ao conectar ao WhatsApp. Tente novamente.');
    }
  };

  const handleCancel = () => {
    onFormVisibilityChange(false);
  };



  return (
    <>
      <ConfigForm onSubmit={handleSubmit}>
        {/* <FormGroup>
          <Label htmlFor="whatsapp-type">Tipo de WhatsApp</Label>
          <Select
            id="whatsapp-type"
            value={whatsappType}
            onChange={(e) => setWhatsappType(e.target.value)}
          >
            <option value="WHATSAPP-BAILEYS">WhatsApp Pessoal</option>
            <option value="WHATSAPP-BUSINESS">WhatsApp Business</option>
          </Select>
        </FormGroup> */}

        <FormGroup>
          <Label htmlFor="phone-number">Número de Telefone</Label>
          <Input
            id="phone-number"
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Ex: 5511999999999"
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </FormGroup>

        <ButtonGroup>
          <Button type="button" onClick={handleCancel}>Cancelar</Button>
          <Button type="submit">Conectar</Button>
        </ButtonGroup>
      </ConfigForm>


    </>
  );
};

export default ConfigurationForm;