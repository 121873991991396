import React from 'react';
import styled from 'styled-components';
import { CheckCircle2, XCircle, Camera, AlertCircle } from 'lucide-react';
import { iChecklistAnswer } from '../Repository/CheckListRepo';

const Card = styled.div`
  background: white;
  border-radius: 0.5rem;
  padding: 1.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  border-left: 4px solid #3b82f6;
`;

const Category = styled.div`
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Question = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
`;

const Response = styled.div`
  background-color: #f3f4f6;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 0.75rem;
`;

const ResponseValue = styled.div`
  color: #374151;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Observation = styled.div`
  color: #6b7280;
  font-size: 0.875rem;
  font-style: italic;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  border-left: 2px solid #e5e7eb;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding-top: 0.75rem;
  border-top: 1px solid #e5e7eb;
`;

const PhotoIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #6b7280;
  font-size: 0.75rem;
`;

const DateInfo = styled.div`
  color: #6b7280;
  font-size: 0.75rem;
`;

interface AnswerCardProps {
    answer: iChecklistAnswer;
}

export const AnswerCard: React.FC<AnswerCardProps> = ({ answer }) => {
    const formatDate = (dateString: string) => {
        return new Date(dateString).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const getResponseDisplay = () => {
        if (!answer.response) return 'Não respondido';

        switch (answer.input_type) {
            case 'boolean':
                return answer.response === 'true' ? (
                    <>
                        <CheckCircle2 size={16} color="#22c55e" />
                        <span>Sim</span>
                    </>
                ) : (
                    <>
                        <XCircle size={16} color="#ef4444" />
                        <span>Não</span>
                    </>
                );
            case 'select':
            case 'multipleselect':
                try {
                    const options = JSON.parse(answer.response_options);
                    const responses = answer.response.split(',');
                    return responses.map(r => options[parseInt(r)]).join(', ');
                } catch {
                    return answer.response;
                }
            default:
                return answer.response;
        }
    };

    const photoCount = [answer.photo_1_url, answer.photo_2_url, answer.photo_3_url]
        .filter(Boolean).length;

    return (
        <Card>
            <Category>
                <AlertCircle size={16} />
                {answer.category_name}
            </Category>
            <Question>{answer.question}</Question>

            <Response>
                <ResponseValue>{getResponseDisplay()}</ResponseValue>
                {answer.observation && (
                    <Observation>{answer.observation}</Observation>
                )}
            </Response>

            <Footer>
                <PhotoIndicator>
                    <Camera size={14} />
                    {photoCount} foto{photoCount !== 1 ? 's' : ''}
                </PhotoIndicator>
                <DateInfo>
                    Respondido em: {formatDate(answer.created_at)}
                </DateInfo>
            </Footer>
        </Card>
    );
};