import { Column, SelectColumn } from 'react-data-grid';
import { IconSvgButton, Row } from '../../StylePages';
import Tooltip from '../../../components/datagridComp/Tooltip';
import { itemDriver } from '../repository/driversRepository';
import { FolderOpen, Pencil } from 'lucide-react';
import StatusChip from '../../Chips/Components/ChipStatus';
import { getDateView } from '../../../utils/Converter';


const listperfil = [
    { value: 1, label: "App Xdriver Completo" },
    { value: 3, label: "App Xdriver Básico" },
    { value: 4, label: "Teclado GT-601" },
];



export const getColumnsDrivers = (
    editItem: (row: itemDriver) => void,
    viewItem: (row: itemDriver) => void
): Column<itemDriver>[] => {
    const columns: Column<itemDriver>[] = [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        {
            key: 'edit',
            name: 'Ações',
            width: 90,
            renderCell(props: any) {
                return (
                    <Row>
                        <IconSvgButton onClick={() => viewItem(props.row)}>
                            <Tooltip text={'Detalhes'} >
                                <FolderOpen size={20} />
                            </Tooltip>
                        </IconSvgButton>
                        <IconSvgButton onClick={() => editItem(props.row)}>
                            <Tooltip text={'Editar'} >
                                <Pencil size={20} />
                            </Tooltip>
                        </IconSvgButton>
                    </Row>
                )
            }
        },
        // Coluna para ID
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Coluna para nome
        {
            key: 'DriverName',
            name: 'Nome do Usuário',
            width: 200,
            resizable: true,
        },
        // Coluna para login
        {
            key: 'idperfil',
            name: 'Jornada',
            width: 180,
            resizable: true,
            renderCell(props: any) {
                const { row } = props;

                console.log(row)
                if (row.isJornada === 1) {
                    console.log(row.isJornada)
                    const perfil = listperfil.find(item => item.value === row.idperfil);
                    console.log(perfil)
                    if (perfil) {
                        return (
                            perfil.label
                        );
                    }
                }

                return "Desativado"; // ou retornar um fallback, como <span>-</span>
            }
        },
        // Coluna para login
        {
            key: 'login',
            name: 'Login',
            width: 130,
            resizable: true
        },
        {
            key: 'tel',
            name: 'Telefone',
            width: 120,
            resizable: true,
        },
        {
            key: 'email',
            name: 'E-mail',
            width: 180,
            resizable: true,
        },
        {
            key: 'cnhValidate',
            name: 'CNH Validade',
            width: 120,
            resizable: true,
            renderCell(props: any) {
                return (
                    <>{getDateView(props.row.cnhValidate)}</>
                )
            }

        },
        {
            key: 'numberCNH',
            name: 'CNH',
            width: 120,
            resizable: true,
        },
        {
            key: 'ender',
            name: 'Endereço',
            width: 180,
            resizable: true,
        },




    ];

    return columns;
};

