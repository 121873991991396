import { styled } from "styled-components";

export const Button = styled.button<{ variant?: 'secondary' | 'text' }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: ${props => props.variant === 'secondary' ? '#e5e7eb' : props.variant === 'text' ? 'transparent' : '#4b5563'};
  color: ${props => props.variant === 'text' ? '#4b5563' : props.variant === 'secondary' ? '#374151' : 'white'};
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  justify-content: center;
  
  &:hover {
    background: ${props => props.variant === 'secondary' ? '#d1d5db' : props.variant === 'text' ? '#f3f4f6' : '#374151'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }`
    ;