import { httpAxiosV4 } from "../../../gateways/Axios";




export async function validTokenServer(token: string): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `shared-links/${token}`,
        "GET"
    );
    return response;
}


export async function getListSharedVehicles(): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `shared-links`,
        "GET"
    );
    return response;
}


export async function upsertSharedLink(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `shared-links`,
        "POST",
        payload
    );
    return response;
}

export async function deleteSharedLink(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `shared-links`,
        "DELETE",
        payload
    );
    return response;
}
export async function setActiveMode(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `shared-links-active`,
        "PUT",
        payload
    );
    return response;
}


export interface VehicleDataShared {
    id: number;
    gpslat: string;
    gpslng: string;
    gpscursor: number;
    gpsvel: number;
    txdtEquip: string;
    icone: string;
    icon_type: number;
    apelido: string;
    vehicle_photo: string;
    id_driver: number;
    DriverName: string;
    ign: string;
    DriverAvatar: string;
    vehicle_placa: string;
    model_vehicle: string;
    manufacturer_vehicle: string;
}

export interface SharedLink {
    id: string;
    token: string;
    active: boolean;
    description: string;
    vehicle_ids: number[];
    expires_at: string;
    created_at: string;
}
