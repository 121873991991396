import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ParkingIcon } from '../../../assets/icons_alerts/parking-sign.svg';
import { ReactComponent as RouteIcon } from '../../../assets/icons_alerts/route.svg';
import { formateTime } from '../utilsMap/tools';
import { JourneyData } from '../repository/MapsRepo';
import { formatDateToScreenDayJs, formatDateToScreenString } from '../../../utils/Converter';
import dayjs from 'dayjs';

// Tipagem para os dados da jornada


interface JourneyTimelineProps {
  data: JourneyData;
}

// Componente principal
const JourneyTimeline: React.FC<JourneyTimelineProps> = ({ data }) => {
  return (
    <TimelineContainer>
      <JourneyStep >
        {data.isStop ? <ParkingIconStyled /> : <RouteIconStyled />}
        <TimeInfo>
          <Time>{formatDateToScreenString(data.dtStartDate.toLocaleString())}</Time>
          {data.isStop ? (
            <>
              <Date>Parado por {formateTime(Number(data.duration))}</Date>
              <Location>{data.adrressStart}</Location>
            </>
          ) : (
            <>
              <Location>{data.adrressStart}</Location>
              <DistanceTime>
                <span>{data.distance.toFixed(2)}km</span> em <span>{formateTime(Number(data.duration))}</span>
              </DistanceTime>
              <EndTimeInfo>
                <Time>{formatDateToScreenString(data.dtEndDate.toLocaleString())}</Time>
                <Location>{data.adrressStart}</Location>
              </EndTimeInfo>
            </>
          )}
        </TimeInfo>
      </JourneyStep>
    </TimelineContainer>
  );
};

// Estilização com Styled Components
const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const JourneyStep = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; /* Alinha o ícone e as informações no centro verticalmente */
  gap: 2px; /* Espaçamento entre o ícone e as informações */
`;

const TimeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 80px;
`;

const EndTimeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
`;

const Time = styled.span`
  font-size: 14px;
`;

const Date = styled.span`
  font-size: 12px;
  color: #888;
`;

const Location = styled.div`
  font-size: 12px;
  font-weight: normal;
  color: #888;
`;

const DistanceTime = styled.div`
  display: flex;
  gap: 5px;
  font-size: 12px;
  color: #3d3d3d;
`;

const ParkingIconStyled = styled(ParkingIcon)`
  width: 32px;
  height: 32px;
  margin-right: 8px; /* Espaçamento entre o ícone e as informações */
`;

const RouteIconStyled = styled(RouteIcon)`
  width: 32px;
  height: 32px;
  margin-right: 8px; /* Espaçamento entre o ícone e as informações */
`;

export default JourneyTimeline;
