import { styled } from 'styled-components';
import { MapConfig } from '../utilsMap/mapContext';
import MapTab from './MapTab';
import VehiclesTab from './VehiclesTab';
import SettingsVehicelTab from './SettingsVehicelTab';
import TabHistoryV2 from './TabHistory';
import TabReports from './TabReports';
import { ReportProvider } from '../utilsMap/ReportContext';
import IconGrid from '../../IconsMap/IconGrid';
import ShareVehiclesPage from '../../Shared/ShareVehiclesPage';


const ContentContainer = styled.div`
  padding: 1px;
`;



const MapTabContainer: React.FC = () => {
    const { tabActive } = MapConfig();

    const renderContent = () => {
        switch (tabActive) {
            case 'MAPA':
                return <MapTab />;
            case 'HISTORY_VEHICLE':
                return <TabHistoryV2 />;
            case 'VEHICLES':
                return <VehiclesTab />;
            case 'SETINGS_VEHICLE':
                return <SettingsVehicelTab />;
            case 'SHARE_VEHICLES':
                return <ShareVehiclesPage />;
            case 'REPORTS':
                return <ReportProvider><TabReports /></ReportProvider>;
            default:
                return null;
        }
    };


    return (

        <ContentContainer>
            {renderContent()}
        </ContentContainer>
    );
};

export default MapTabContainer;