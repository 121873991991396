import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR } from 'date-fns/locale';
import { styled } from 'styled-components';

registerLocale('ptBR', ptBR)


const Label = styled.label`
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 3px;
`;

interface DatePickerInputProps {
  label?: string;
  value: Date | null;
  onChange: (date: Date) => void;
  width?: string;
}

const DatePickerInput: React.FC<DatePickerInputProps> = ({ label, value, width, onChange }) => {
  const [error, setError] = useState('');

  const handleChange = (date: Date | null, event?: any) => {
    setError('');

    if (event?.type === 'change' && !date && event.target.value) {
      setError('Please enter a valid date in MM/DD/YYYY format');
      return;
    }

    if (date) {
      onChange(date);
    }
  };


  const handleInputChange = (e: any) => {
    if (!e || !e.target) return;

    const input = e.target as HTMLInputElement;
    const cursorPosition = input.selectionStart || 0;
    const previousValue = input.value;
    console.log('previousValue', input);

    const formattedValue = formatDateInput(previousValue);

    if (formattedValue !== previousValue) {
      input.value = formattedValue;

      // Calcular nova posição do cursor considerando as barras adicionadas
      const addedSlashes =
        (formattedValue.match(/\//g) || []).length -
        (previousValue.match(/\//g) || []).length;

      setTimeout(() => {
        const newPosition = cursorPosition + addedSlashes;
        input.setSelectionRange(newPosition, newPosition);
      }, 0);
    }
  };

  // Função para formatar o input como dd/mm/yyyy
  const formatDateInput = (value: string): string => {
    const digitsOnly = value.replace(/\D/g, '').slice(0, 8); // Limita a 8 dígitos (ddmmyyyy)

    const day = digitsOnly.substring(0, 2);
    const month = digitsOnly.substring(2, 4);
    const year = digitsOnly.substring(4, 8);

    let formatted = '';
    if (day) formatted += day;
    if (month) formatted += '/' + month;
    if (year) formatted += '/' + year;

    return formatted;
  };

  return (
    <Container width={width}>
      {label && <Label>{label}</Label>}

      <DatePickerWrapper>
        <StyledDatePicker
          locale="ptBR"
          selected={value}
          onChange={handleChange}
          dateFormat="dd/MM/yyyy"
          placeholderText="DD/MM/YYYY"
          hasError={!!error}
          // onChangeRaw={handleInputChange}
          // maxDate={new Date()}
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          autoComplete="off"
        />
      </DatePickerWrapper>

      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  );

};

export default DatePickerInput;


interface InputContainerProps {
  width?: string; // Ex: "100%", "300px", "50vw"
}

// Container geral do componente
const Container = styled.div<InputContainerProps>`
  width: ${({ width = '100%' }) => width};
`;

// Wrapper do DatePicker (position relative)
const DatePickerWrapper = styled.div`
  position: relative;
`;

// Estilização do input do DatePicker
const StyledDatePicker = styled(DatePicker) <{ hasError?: boolean }>`
  width: 100%;
  padding: 8px 16px;
  border: 1px solid ${({ hasError }) => (hasError ? '#f56565' : '#D1D5DB')};
  border-radius: 6px;

  &:focus {
    outline: 2px solid ${({ hasError }) => (hasError ? '#f56565' : '#3B82F6')};
    border-color: ${({ hasError }) => (hasError ? '#f56565' : '#3B82F6')};
  }
`;

// Mensagem de erro
const ErrorText = styled.p`
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #f56565;
`;