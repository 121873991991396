// Sidebar.tsx
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledSidebar = styled.div<{ isOpen: boolean; width: string }>`
  width: ${(props) => (props.isOpen ? props.width : '0')};
  height: 100%;
  position: fixed;
  z-index: 1000; /* Acima de outros elementos */
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: width 0.3s;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.5);
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*background-color: darkorange;*/
  padding: 10px;
  color: #4b5563;
  font-weight: 600;
  border-bottom: 3px solid #4b5563;
`;

const Backdrop = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999; /* Abaixo do sidebar, mas acima de outros elementos */
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: large;
  cursor: pointer;
`;

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  width?: string;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose, children, title, width = '400px' }) => {
  return (
    <>
      <StyledSidebar isOpen={isOpen} width={width}>
        <SidebarHeader>
          <h1>{title ?? ''}</h1>
          <CloseButton onClick={onClose}>X</CloseButton>
        </SidebarHeader>
        {children}
      </StyledSidebar>
      {isOpen && <Backdrop isOpen={isOpen} onClick={onClose} />}
    </>
  );
};

export default Sidebar;
