const eventDescriptions: { [key: string]: string } = {
    "DB_SPEEDING": "Excesso de velocidade",
    "DB_HARD_ACCEL": "Aceleração brusca",
    "DB_LEFT_TURN": "Curva à esquerda",
    "DB_RIGHT_TURN": "Curva à direita",
    "DB_HARD_BRAKE": "Frenagem brusca",
    "DB_FATIGUE_DRIVE": "Condução com fadiga",
    "DB_ABRUPT_TURN": "Curva abrupta",
    "DB_SPEED_WARN": "Aviso de velocidade",

    "PTA_DOOR_ABNORM": "Porta anormal",
    "PTA_LOUD_SOUND": "Som alto",
    "PTA_DOOR_OPEN": "Porta aberta",
    "PTA_DOOR_CLOSE": "Porta fechada",
    "PTA_FUEL_STOLEN": "Combustível roubado",
    "PTA_TEMP_HIGH": "Temperatura alta",
    "PTA_TEMP_LOW": "Temperatura baixa",
    "PTA_TEMP_NORMAL": "Temperatura normal",
    "PTA_TANK_REFILL": "Reabastecimento",
    "PTA_FUEL_ABNORM": "Combustível anormal",
    "PTA_HUMID_HIGH": "Umidade alta",
    "PTA_ENV_ABNORM": "Ambiente anormal",

    "GNSS_GEOFENCE_ENTER": "Entrada em geofence",
    "GNSS_GEOFENCE_EXIT": "Saída de geofence",
    "GNSS_DEAD_ZONE_ENTER": "Entrada em zona morta",
    "GNSS_DEAD_ZONE_EXIT": "Saída de zona morta",
    "GNSS_FIRST_FIX": "Primeira correção GNSS",

    "DS_DEVICE_OK": "Dispositivo OK",
    "DS_POWER_CUT": "Corte de energia",
    "DS_POWER_ON": "Energia ligada",
    "DS_LOW_POWER_VOLTAGE": "Baixa voltagem",
    "DS_PROTECTION_MODE": "Modo de proteção",
    "DS_SIM_CHANGED": "SIM alterado",
    "DS_MANUAL_POWER_OFF": "Desligamento manual",
    "DS_AIRPLANE_MODE": "Modo avião",
    "DS_DEVICE_REMOVED": "Dispositivo removido",
    "DS_SHUTDOWN": "Desligamento",
    "DS_COVER_OPENED": "Tampa aberta",
    "DS_LOW_BATTERY": "Bateria baixa",
    "DS_SLEEP_MODE": "Modo de suspensão",
    "DS_PLUGGED_OUT": "Desconectado",
    "DS_LAND_TRANSPORT": "Transporte terrestre",
    "DS_WATER_TRANSPORT": "Transporte aquático",
    "DS_STATIONERY_MODE": "Modo estacionário",
    "DS_PLUGGED_IN": "Conectado",

    "VS_FUEL_POWER_RECONNECT": "Reconexão de combustível/potência",
    "VS_FUEL_POWER_DISCONNECT": "Desconexão de combustível/potência",
    "VS_ENGINE_FAIL": "Falha no motor",
    "VS_BATTERY_UNDERVOLTAGE": "Subtensão da bateria",
    "VS_LONG_PARK": "Estacionamento prolongado",
    "VS_HIGH_WATER_TEMP": "Temperatura alta da água",
    "VS_IGNITION_ON": "Ignição ligada",
    "VS_IGNITION_OFF": "Ignição desligada",

    "PS_EMERGENCY_ALERT": "Alerta de emergência",
    "PS_FALL_DETECTED": "Queda detectada",
    "PS_UNEXPECTED_OPEN": "Abertura inesperada",
    "PS_VIOLENT_HIT": "Impacto violento",
    "PS_KNOWN_OPEN": "Abertura conhecida",
    "PS_BODY_TEMP_ABNORMAL": "Temperatura corporal anormal",
    "PS_KNOWN_VIOLENT_HIT": "Impacto violento conhecido",

    "VS_VIBRATION": "Vibração",
    "VS_MOVED": "Movido",
    "VS_COLLISION": "Colisão",
    "VS_REAR_MIRROR_VIBRATION": "Vibração do retrovisor",

    "AGA_TIPPED_OVER": "Virado",
    "AGA_ABRUPT_TURN": "Curva abrupta",
    "AGA_LANE_CHANGE": "Mudança de faixa",
    "AGA_STABILITY_EXC": "Excesso de estabilidade",
    "AGA_ATTITUDE_EXC": "Excesso de atitude",
    "AGA_BLINKING": "Piscando",
    "AGA_DISTRACTED": "Distraído",
    "AGA_NO_FACE": "Sem face",
    "AGA_USING_PHONE": "Usando o telefone",
    "AGA_SMOKING": "Fumando",
    "AGA_YAWNING": "Bocejando",
    "AGA_COLLISION_WARN": "Aviso de colisão",
    "AGA_LANE_DRIFT": "Desvio de faixa",
    "AGA_FOLLOWING_CLOSE": "Seguindo de perto",
    "AGA_PED_COLLISION": "Colisão com pedestre",

    "OA_PSEUDO_BASE": "Base pseudo",
    "OA_EXIT_TRANSPORT": "Saída de transporte",
    "OA_HERD_LEAVE": "Saída de rebanho",
    "OA_AIRBAG_DEPLOY": "Airbag acionado",
    "OA_DEEP_SLEEP": "Sono profundo",
    "OA_CHARGER_CONNECTED": "Carregador conectado",
    "OA_LIGHT_DETECTED": "Luz detectada",
    "OA_BEACON_MOVE": "Movimento do farol",
    "OA_HERD_LEFT": "Rebanho à esquerda",
    "OA_DEVICE_LOCKED": "Dispositivo bloqueado",
    "OA_DEVICE_UNLOCKED": "Dispositivo desbloqueado",
    "OA_UNLOCK_UNEXP": "Desbloqueio inesperado",
    "OA_UNLOCK_FAILED": "Falha no desbloqueio",
    "OA_OUT_OF_RANGE": "Fora de alcance",
    "OA_STATIONARY_LONG": "Estacionário por muito tempo",
    "OA_VEHICLE_STOLEN": "Veículo roubado",
    "OA_VEHICLE_STARTED": "Veículo ligado",
    "OA_KEY_PRESS": "Pressão da tecla",
    "OA_DEFENSE_EXIT": "Saída de defesa",
    "OA_DEFENSE_ENTER": "Entrada de defesa",
    "OA_DEVICE_MUTED": "Dispositivo silenciado",
    "OA_FINDING_ALERT": "Alerta de localização",
    "OA_TRUNK_OPEN": "Porta-malas aberto",
    "OA_RSV1": "RSV1",
    "OA_RSV2": "RSV2",
    "OA_RSV3": "RSV3",
    "OA_PET_LOST": "Pet perdido",
    "OA_BATTERY_FULL": "Bateria cheia",
    "OA_BATTERY_ERROR": "Erro na bateria",
    "OA_TILT_UNEXP": "Inclinação inesperada",
    "OA_GNSS_DISCONN": "Desconexão GNSS",
    "OA_BATTERY_HIGH_TEMP": "Alta temperatura da bateria",
    "OA_BATTERY_CHARGING": "Carregando a bateria",
    "OA_BATTERY_CHARGED": "Bateria carregada",
    "OA_BATTERY_NEAR_FULL": "Bateria quase cheia",
    "OA_BATTERY_COMPLETE": "Bateria completa",
    "OA_RFID_ERROR": "Erro de RFID",
    "OA_PULSE_EXC": "Excesso de pulso",
    "OA_SPEEDING_GEOFENCE": "Excesso de velocidade em geofence",
    "OA_LIVE_WIRE_EXC": "Excesso de fio ao vivo",
    "OA_TEMP_SENSOR_ERROR": "Erro no sensor de temperatura",
    "OA_HIGH_VOLTAGE": "Alta voltagem",
    "OA_BLUETOOTH_CLOSE": "Bluetooth próximo",
    "OA_VOLTAGE_EXC": "Excesso de voltagem",
    "OA_SIGNED_IN": "Conectado",
    "OA_SIGNED_OUT": "Desconectado",
    "OA_FILE_UPLOADED": "Arquivo enviado",
    "OA_TIPPED_OVER": "Virado",
    "OA_SD_MOUNTED": "SD montado",
    "OA_INSTALLED": "Instalado",
    "OA_SPEED_NORMAL": "Velocidade normal",
    "OA_FATIGUE_KNOWN": "Fadiga conhecida",
    "OA_TEMP_SENSOR_TIMEOUT": "Tempo esgotado do sensor de temperatura",
    "OA_HIGH_VOLTAGE_ADC1": "Alta voltagem ADC1",
    "OA_LOW_VOLTAGE_ADC1": "Baixa voltagem ADC1",
    "OA_VOLTAGE_RISING_ADC1": "Voltagem aumentando ADC1",
    "OA_VOLTAGE_DROPPING_ADC1": "Voltagem caindo ADC1",
    "OA_TEMP_RISING": "Temperatura subindo",
    "OA_TEMP_DROPPING": "Temperatura caindo",
    "OA_MOBILE_DATA_EXC": "Excesso de dados móveis",
    "OA_RESTARTED": "Reiniciado",
    "OA_CAMERA1_EXC": "Excesso na câmera 1",
    "OA_CAMERA2_EXC": "Excesso na câmera 2",
    "OA_NO_SD_CARD": "Sem cartão SD",
    "OA_NO_USB_CAMERA": "Sem câmera USB",
    "OA_CAPTURE_COMPLETE": "Captura completa",
    "OA_DRIVER_INFO_CHANGE": "Mudança de informações do motorista",
    "OA_CAMERA_BLOCKED": "Câmera bloqueada",
    "OA_FACE_ALIGN_ERROR": "Erro de alinhamento de face",
    "OA_HEAD_DOWN": "Cabeça baixa",
    "OA_LOW_MEM_CARD_SPACE": "Pouco espaço no cartão de memória",
    "OA_RFID_SWIPE": "Passe de RFID",
    "OA_SEATBELT_ON": "Cinto de segurança ligado",
    "OA_SEATBELT_OFF": "Cinto de segurança desligado",
    "OA_DRINKING": "Bebendo",
    "OA_PACKAGE_OPEN": "Pacote aberto",
    "OA_BLUETOOTH_MAC_FOUND": "MAC do Bluetooth encontrado",
    "OA_NO_BLUETOOTH_MAC": "Sem MAC do Bluetooth",
    "OA_FUEL_LEVEL_INCREASE": "Aumento do nível de combustível",
    "OA_FUEL_LEVEL_DROP": "Queda do nível de combustível",
    "OA_FUEL_SENSOR_ERROR": "Erro no sensor de combustível",
    "OA_FUEL_SENSOR_RESUME": "Resumir sensor de combustível",
    "OA_TEMP_SENSOR_ERROR_1W": "Erro no sensor de temperatura 1W",
    "OA_TOWING_AWAY": "Reboque",
    "OA_POSITION_FIX_LONG": "Correção de posição longa",
    "OA_VEHICLE_IDLING": "Veículo em marcha lenta",
    "OA_ACCEL_SENSOR_ERROR": "Erro no sensor de aceleração",
    "OA_GNSS_MODULE_ERROR": "Erro no módulo GNSS",
    "OA_UBI_SENSOR_ERROR": "Erro no sensor UBI",
    "OA_UBI_ENCRYPTED_IC_ERROR": "Erro no IC criptografado UBI",
    "OA_UBI_GNSS_ERROR": "Erro no GNSS UBI",
    "OA_ENGINE_ON": "Motor ligado",
    "OA_ENGINE_OFF": "Motor desligado",
    "OA_EXTENDED_DRIVING": "Condução prolongada",
    "OA_EXTENDED_DRIVING_KNOWN": "Condução prolongada conhecida",
    "OA_INPUT1_ACTIVATED": "Entrada 1 ativada",
    "OA_FLASH_ERROR": "Erro no flash",
    "OA_CAN_MODULE_ERROR": "Erro no módulo CAN",
    "OA_DEPARTED_LANE": "Saída de faixa",
    "OA_FORWARD_COLLISION": "Colisão frontal",

    //Additional Alarm Type
    "ALERT_FLG": "Alerta de bandeira",
    "VID_LOSS": "Perda de vídeo",
    "VID_BLCK": "Vídeo bloqueado",
    "STOR_FAIL": "Falha de armazenamento",
    "VID_EQUIP": "Equipamento de vídeo",
    "OVERLOAD": "Sobrecarga",
    "DRIVE_ABN": "Direção anormal",
    "STOR_THR": "Armazenamento de ameaça",
    "ADAS_ALM": "Alarme ADAS",
    "DMS_ALM": "Alarme DMS",

    "SHRP_ACCEL": "Aceleração brusca",
    "SHRP_DECEL": "Desaceleração brusca",
    "SHRP_TURN": "Curva brusca",
    "OVRSPD_ALM": "Alarme de excesso de velocidade",
    "FATIG_ALM": "Alarme de fadiga",
    "COLL_ALM": "Alarme de colisão",
    "PARK_VIB": "Vibração ao estacionar",
    "DISPLACE": "Deslocamento",
    "FENCE_ENT": "Entrada na cerca",
    "FENCE_EXT": "Saída da cerca",
    "SLEEP_EVT": "Evento de sono",
    "WORK_EVT": "Evento de trabalho",
    "SOS_ALM": "Alarme SOS",
    "LOW_PWR": "Baixa potência",
    "ACC_ON": "Ignição ligada",
    "ACC_OFF": "Ignição desligada",
    "ANTI_TFT": "Anti-roubo",
    "CALIB_ABN": "Calibração anormal",
    "ID_ALM": "Alarme de ID",
    "DOOR_ALM": "Alarme de porta",
    "OIL_ALM": "Alarme de óleo",
    "TEMP_HUM": "Temperatura e umidade",
    "DLT_LOGIN": "Login do motorista",
    "DLT_LOGOUT": "Logout do motorista",
    "DLT_NREG": "Motorista não registrado",
};

export function getEventDescription(event: string): string {
    return eventDescriptions[event] || "Descrição não encontrada";
}