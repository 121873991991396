import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowLeft, Plus, X, Edit, Trash2 } from 'lucide-react';
import { Button } from '../components/Button';
import { Input, Select } from '../components/Input';
import { Modal } from '../components/Modal';
import { ChecklistTemplate, ChecklistCategory, ChecklistItem, InputType, ChecklistItemForm } from '../types/checklist';
import { getCategoriesByTemplateId, createOrUpdateCategory, getItemsByCategoryId, createOrUpdateItens, deleteItem, deleteCategory } from '../Repository/CheckListRepo';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  min-height: 100vh;
  background-color: #f3f4f6;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
  height: calc(100vh - 8rem);
`;

const CategoryList = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
`;

const CategoryInput = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #f9fafb;
  border-radius: 0.375rem;
`;

const CategoriesContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  margin: 0 -1rem;
  padding: 0 1rem;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9ca3af;
  }
`;

const CategoryItem = styled.div`
  padding: 0.75rem;
  border-radius: 0.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid transparent;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: #f3f4f6;
    border-color: #e5e7eb;
  }

  &.active {
    background-color: #e5e7eb;
    border-color: #d1d5db;
  }
`;

const CategoryName = styled.div`
  flex: 1;
  margin-right: 0.5rem;
`;

const ItemsList = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ItemsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
`;

const ItemsContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  margin: 0 -1rem;
  padding: 0 1rem;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9ca3af;
  }
`;

const ItemCard = styled.div`
  padding: 1rem;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 0.2s;

  &:hover {
    background-color: #f3f4f6;
    border-color: #d1d5db;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemInfo = styled.div`
  flex: 1;
`;

const ItemActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #374151;
  }
`;

const SelectOptionsContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f9fafb;
  border-radius: 0.375rem;
`;

const OptionsList = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const OptionItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e5e7eb;
`;

const CategoryActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ModalContent = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 0.5rem;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9ca3af;
  }
`;

interface TemplateDetailsProps {
  template: ChecklistTemplate;
  onClose: () => void;
}

const TemplateDetails: React.FC<TemplateDetailsProps> = ({ template, onClose }) => {
  const [categories, setCategories] = useState<ChecklistCategory[]>([]);
  const [items, setItems] = useState<ChecklistItem[]>([]);
  const [categoryItems, setCategoryItems] = useState<ChecklistItem[]>([]);
  const [newCategory, setNewCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<ChecklistCategory | null>(null);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<ChecklistItem | null>(null);
  const [categoryToDelete, setCategoryToDelete] = useState<ChecklistCategory | null>(null);
  const [editingCategory, setEditingCategory] = useState<ChecklistCategory | null>(null);
  const [editingItem, setEditingItem] = useState<ChecklistItem | null>(null);
  const [newItem, setNewItem] = useState<ChecklistItemForm>({
    description: '',
    input_type: 'text' as InputType,
    requires_photo: false,
    response_options: [],
    has_observation: false
  });
  const [newOption, setNewOption] = useState('');

  useEffect(() => {
    fetchCategoryData();
  }, [template]);

  const fetchCategoryData = async () => {
    try {
      const id = template?.id;
      const fetchedCategories = await getCategoriesByTemplateId(id);
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  const fetchItemData = async () => {
    try {
      if (!selectedCategory) return;
      const id = selectedCategory?.id;
      const fetchedItens = await getItemsByCategoryId(id);
      setCategoryItems(fetchedItens);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  const handleAddCategory = async () => {
    if (!newCategory.trim() || !template) return;

    const newCategoryItem: Partial<ChecklistCategory> = {
      template_id: template.id,
      name: newCategory,
    };

    try {
      await createOrUpdateCategory(newCategoryItem);
      setNewCategory('');
      fetchCategoryData();
    } catch (error) {
      console.error('Erro ao adicionar categoria:', error);
    }
  };

  const handleEditCategory = (category: ChecklistCategory) => {
    setEditingCategory(category);
    setNewCategory(category.name);
  };

  const handleSelectedCategory = (category: ChecklistCategory) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    if (selectedCategory) {
      fetchItemData();
    }
  }, [selectedCategory]);

  const handleUpdateCategory = async () => {
    if (!editingCategory || !newCategory.trim()) return;

    const updatedCategories: Partial<ChecklistCategory> = {
      uuid: editingCategory.uuid,
      template_id: editingCategory.template_id,
      name: newCategory,
    };


    await createOrUpdateCategory(updatedCategories);
    setEditingCategory(null);
    setNewCategory('');
    fetchCategoryData();
  };

  const handleDeleteCategory = (category: ChecklistCategory) => {
    setCategoryToDelete(category);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteCategory = async () => {
    if (!categoryToDelete) return;
    await deleteCategory(categoryToDelete.uuid);
    fetchCategoryData();
    setCategoryToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const handleAddOption = () => {
    if (!newOption.trim()) return;
    setNewItem(prev => ({
      ...prev,
      response_options: [...(prev.response_options || []), newOption]
    }));
    setNewOption('');
  };

  const handleRemoveOption = (index: number) => {
    setNewItem(prev => ({
      ...prev,
      response_options: prev.response_options?.filter((_, i) => i !== index) || []

    }));
  };

  const handleEditItem = (item: ChecklistItem) => {
    setEditingItem(item);
    setNewItem({
      description: item.description,
      input_type: item.input_type,
      requires_photo: item.requires_photo,
      response_options: item.response_options,
      has_observation: item.has_observation
    });
    setIsItemModalOpen(true);
  };

  const handleDeleteItem = (item: ChecklistItem) => {
    setItemToDelete(item);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteItem = async () => {
    if (!itemToDelete) return;
    await deleteItem(itemToDelete.uuid);
    fetchItemData();
    setItemToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const handleSaveItem = async () => {
    if (!selectedCategory || !newItem.description.trim()) return;

    if (editingItem) {
      const newItemData = {
        uuid: editingItem.uuid,
        category_id: selectedCategory.id,
        template_id: template.id,
        description: newItem.description,
        input_type: newItem.input_type,
        response_options: newItem.response_options,
        requires_photo: newItem.requires_photo,
      };
      await createOrUpdateItens(newItemData);
    } else {
      const newItemData = {
        category_id: selectedCategory.id,
        template_id: template.id,
        description: newItem.description,
        input_type: newItem.input_type,
        response_options: newItem.response_options,
        requires_photo: newItem.requires_photo,
      };
      await createOrUpdateItens(newItemData);
    }
    fetchItemData();
    setIsItemModalOpen(false);
    setEditingItem(null);
    setNewItem({
      description: '',
      input_type: 'text',
      requires_photo: false,
      response_options: [],
      has_observation: false
    });
  };


  if (!template) {
    return <div>Template não encontrado</div>;
  }

  return (
    <Container>
      <Header>
        <Button className="secondary" onClick={onClose}>
          <ArrowLeft size={20} />
          Voltar
        </Button>
        <Title>{template.name}</Title>
      </Header>

      <Content>
        <CategoryList>
          <CategoryHeader>
            <h2 className="text-lg font-semibold">Categorias</h2>
          </CategoryHeader>

          <CategoryInput>
            <Input
              type="text"
              placeholder="Nova categoria"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
            {newCategory && (
              <Button className="icon secondary" onClick={() => setNewCategory('')}>
                <X size={20} />
              </Button>
            )}
            <Button
              className="icon secondary"
              onClick={editingCategory ? handleUpdateCategory : handleAddCategory}
            >
              {editingCategory ? <Edit size={20} /> : <Plus size={20} />}
            </Button>
          </CategoryInput>

          <CategoriesContainer>
            {categories.map(category => (
              <CategoryItem
                key={category.id}
                className={selectedCategory?.id === category.id ? 'active' : ''}
                onClick={() => handleSelectedCategory(category)}
              >
                <CategoryName>{category.name}</CategoryName>
                <CategoryActions onClick={(e) => e.stopPropagation()}>
                  <Button className="icon secondary" onClick={() => handleEditCategory(category)}>
                    <Edit size={16} />
                  </Button>
                  <Button className="icon secondary" onClick={() => handleDeleteCategory(category)}>
                    <Trash2 size={16} />
                  </Button>
                </CategoryActions>
              </CategoryItem>
            ))}
          </CategoriesContainer>
        </CategoryList>

        <div>
          {selectedCategory ? (
            <ItemsList>
              <ItemsHeader>
                <h2 className="text-lg font-semibold">Itens - {selectedCategory.name}</h2>
                <Button className="secondary" onClick={() => setIsItemModalOpen(true)}>
                  <Plus size={20} />
                  Adicionar Item
                </Button>
              </ItemsHeader>
              <ItemsContainer>
                {categoryItems.map(item => (
                  <ItemCard key={item.id}>
                    <ItemInfo>
                      <h3 className="font-medium">{item.description}</h3>
                      <p className="text-sm text-gray-500">
                        Tipo: {item.input_type === 'text' ? 'Texto' :
                          item.input_type === 'number' ? 'Número' :
                            item.input_type === 'select' ? 'Seleção' :
                              item.input_type === 'multipleSelect' ? 'Seleção Múltipla' : 'Sim/Não'}
                        {item.requires_photo && ' • Requer foto'}
                        {item.has_observation && ' • Tem observação'}
                      </p>
                    </ItemInfo>
                    <ItemActions>
                      <Button className="icon secondary" onClick={() => handleEditItem(item)}>
                        <Edit size={16} />
                      </Button>
                      <Button className="icon secondary" onClick={() => handleDeleteItem(item)}>
                        <Trash2 size={16} />
                      </Button>
                    </ItemActions>
                  </ItemCard>
                ))}
              </ItemsContainer>
            </ItemsList>
          ) : (
            <div className="text-center text-gray-500 mt-8">
              Selecione uma categoria para ver os itens
            </div>
          )}
        </div>
      </Content>

      <Modal
        isOpen={isItemModalOpen}
        onClose={() => {
          setIsItemModalOpen(false);
          setEditingItem(null);
          setNewItem({
            description: '',
            input_type: 'text',
            requires_photo: false,
            response_options: [],
            has_observation: false
          });
        }}
        title={editingItem ? "Editar Item" : "Adicionar Novo Item"}
      >
        <ModalContent>
          <FormGroup>
            <label htmlFor="description">Descrição</label>
            <Input
              type="text"
              id="description"
              value={newItem.description}
              onChange={(e) => setNewItem(prev => ({ ...prev, description: e.target.value }))}
              placeholder="Digite a descrição do item"
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="input_type">Tipo de Entrada</label>
            <Select
              id="input_type"
              value={newItem.input_type}
              onChange={(e) => setNewItem(prev => ({
                ...prev,
                input_type: e.target.value as InputType,
                response_options: []
              }))}
            >
              <option value="text">Texto</option>
              <option value="number">Número</option>
              <option value="select">Seleção única</option>
              <option value="multipleSelect">Seleção Multipla</option>
              <option value="boolean">Sim/Não</option>
            </Select>
          </FormGroup>

          {(newItem.input_type === 'select' || newItem.input_type === 'multipleSelect') && (
            <SelectOptionsContainer>
              <h3 className="font-medium mb-2">Opções de Seleção</h3>
              <div className="flex gap-2 mb-2">
                <Input
                  type="text"
                  value={newOption}
                  onChange={(e) => setNewOption(e.target.value)}
                  placeholder="Adicionar opção"
                />
                <Button className="secondary" onClick={handleAddOption}>
                  Adicionar
                </Button>
              </div>
              <OptionsList>
                {newItem.response_options?.map((option, index) => (
                  <OptionItem key={index}>
                    <span>{option}</span>
                    <Button className="icon secondary" onClick={() => handleRemoveOption(index)}>
                      <X size={16} />
                    </Button>
                  </OptionItem>
                ))}
              </OptionsList>
            </SelectOptionsContainer>
          )}

          <FormGroup>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={newItem.has_observation}
                onChange={(e) => setNewItem(prev => ({
                  ...prev,
                  has_observation: e.target.checked
                }))}
              />
              Incluir campo de observação
            </label>
          </FormGroup>

          <FormGroup>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={newItem.requires_photo}
                onChange={(e) => setNewItem(prev => ({
                  ...prev,
                  requires_photo: e.target.checked
                }))}
              />
              Requer foto
            </label>
          </FormGroup>

          <div style={{ marginTop: '1rem', display: 'flex', gap: '0.5rem', justifyContent: 'flex-end' }}>
            <Button
              className="secondary"
              onClick={() => {
                setIsItemModalOpen(false);
                setEditingItem(null);
                setNewItem({
                  description: '',
                  input_type: 'text',
                  requires_photo: false,
                  response_options: [],
                  has_observation: false
                });
              }}
            >
              Cancelar
            </Button>
            <Button className="secondary" onClick={handleSaveItem}>
              {editingItem ? 'Salvar Alterações' : 'Salvar'}
            </Button>
          </div>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setItemToDelete(null);
          setCategoryToDelete(null);
        }}
        title={`Confirmar Exclusão de ${itemToDelete ? 'Item' : 'Categoria'}`}
      >
        <p className="mb-4">
          {itemToDelete
            ? `Tem certeza que deseja excluir o item "${itemToDelete.description}"?`
            : categoryToDelete
              ? `Tem certeza que deseja excluir a categoria "${categoryToDelete.name}" e todos os seus itens?`
              : ''}
        </p>
        <div style={{ marginTop: '1rem', display: 'flex', gap: '0.5rem', justifyContent: 'flex-end' }}>
          <Button
            className="secondary"
            onClick={() => {
              setIsDeleteModalOpen(false);
              setItemToDelete(null);
              setCategoryToDelete(null);
            }}
          >
            Cancelar
          </Button>
          <Button
            className="secondary"
            onClick={itemToDelete ? confirmDeleteItem : confirmDeleteCategory}
          >
            Excluir
          </Button>
        </div>
      </Modal>
    </Container>
  );
}

export default TemplateDetails;