import React, { useEffect, useState } from 'react';
import { Input } from './Input';
import { Button } from './Button';
import { VehicleList } from './VehicleList';
import { SharedLink } from '../repository/sharedRepo';
import { CheckSquare, Square, Eye, EyeOff, Search } from 'lucide-react';
import styled from 'styled-components';

const Form = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  max-width: 700px;
  margin: auto;
`;

const FormHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const InputRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: #374151;
`;

const SelectContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  background: white;
  color: #4b5563;
  font-size: 1rem;
`;

const VehicleControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  background: white;
  color: #4b5563;
  font-size: 1rem;
`;

const VehicleCount = styled.div`
  background: #374151;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  color: white;
  font-weight: bold;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 0.5rem;
`;

interface Props {
    onSave: (data: { description: string; vehicleIds: number[]; expiresAt: string }) => void;
    onCancel: () => void;
    editingLink?: SharedLink | null;
    vehicles: any[];
}

export const ShareLinkForm: React.FC<Props> = ({ onSave, onCancel, editingLink, vehicles }) => {
    const [description, setDescription] = useState('');
    const [selectedVehicleIds, setSelectedVehicleIds] = useState<number[]>([]);
    const [expirationTime, setExpirationTime] = useState(24);
    const [vehicleSearchTerm, setVehicleSearchTerm] = useState('');
    const [showSelectedOnly, setShowSelectedOnly] = useState(false);

    const expirationOptions = [
        { label: '1 hora', value: 1 },
        { label: '3 horas', value: 3 },
        { label: '6 horas', value: 6 },
        { label: '12 horas', value: 12 },
        { label: '24 horas', value: 24 },
        { label: '1 dia', value: 24 },
        { label: '7 dias', value: 168 },
        { label: '15 dias', value: 360 },
        { label: '30 dias', value: 720 },
    ];

    const filteredVehicles = vehicles.filter((vehicle) =>
        vehicle.placa.toLowerCase().includes(vehicleSearchTerm.toLowerCase())
    );

    const calculateExpiration = (hours: number): string => {
        const now = new Date();
        now.setHours(now.getHours() + hours);
        return now.toISOString();
    };

    const handleSubmit = () => {
        if (!description || selectedVehicleIds.length === 0) return;

        const expiresAt = calculateExpiration(expirationTime);

        onSave({ description, vehicleIds: selectedVehicleIds, expiresAt });
    };

    return (
        <Form>
            {/* Header com Botões */}
            <FormHeader>
                <Button variant="secondary" onClick={onCancel}>
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} disabled={!description || selectedVehicleIds.length === 0}>
                    {editingLink ? 'Salvar Alterações' : 'Criar Link'}
                </Button>
            </FormHeader>

            {/* Linha com Descrição e Data de Expiração */}
            <InputRow>
                <InputContainer>
                    <Label>Descrição do Link</Label>
                    <Input
                        type="text"
                        placeholder="Digite a descrição"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </InputContainer>
                <SelectContainer>
                    <Label>Expiração</Label>
                    <Select
                        value={expirationTime}
                        onChange={(e) => setExpirationTime(Number(e.target.value))}
                    >
                        {expirationOptions.map((opt) => (
                            <option key={opt.value} value={opt.value}>
                                {opt.label}
                            </option>
                        ))}
                    </Select>
                </SelectContainer>
            </InputRow>

            {/* Pesquisa e Contador de Veículos na Mesma Linha */}
            <TopRow>
                <SearchInput
                    type="text"
                    placeholder="Pesquisar veículos..."
                    value={vehicleSearchTerm}
                    onChange={(e) => setVehicleSearchTerm(e.target.value)}
                />
                <VehicleCount>{selectedVehicleIds.length} selecionados</VehicleCount>
            </TopRow>

            {/* Controles de Seleção Abaixo da Pesquisa */}
            <ButtonRow>
                <Button variant="text" onClick={() => setSelectedVehicleIds(filteredVehicles.map((v) => v.id))}>
                    <CheckSquare size={20} />
                    Selecionar Todos
                </Button>
                <Button variant="text" onClick={() => setSelectedVehicleIds([])}>
                    <Square size={20} />
                    Desmarcar Todos
                </Button>
                <Button variant="text" onClick={() => setShowSelectedOnly(!showSelectedOnly)}>
                    {showSelectedOnly ? <><Eye size={20} /> Mostrar Todos</> : <><EyeOff size={20} /> Mostrar Selecionados</>}
                </Button>
            </ButtonRow>

            {/* Lista de Veículos */}
            <VehicleList
                vehicles={showSelectedOnly ? vehicles.filter((v) => selectedVehicleIds.includes(v.id)) : filteredVehicles}
                selectedIds={selectedVehicleIds}
                onToggleSelect={(id) =>
                    setSelectedVehicleIds((prev) =>
                        prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id]
                    )
                }
            />
        </Form>
    );
};
