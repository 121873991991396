import React, { useState } from 'react';
import ViewListDrivers from '../Components/ViewListDrivers';
import ViewDetailsDriver from '../Components/ViewDetailsDriver';
import CadDriverForms from '../Forms/CadDriverForms';
import { itemDriver } from '../repository/driversRepository';
import Sidebar from '../../../components/datagridComp/SideBar';
import FormDriver from '../Forms/FormDriver';
import { v4 as uuidv4 } from 'uuid';
import { DriverDetails } from '../Components/DriverDetails';


const ListDriversPage: React.FC = () => {

    const [DriverSelected, setDriverSelected] = useState<itemDriver>();
    const [isShowFormDriver, setShowFormDriver] = useState<boolean>(false);
    const [isShowDetailsDriver, setShowDetailsDriver] = useState<boolean>(false);

    const openFormNewDriver = () => {
        setDriverSelected(undefined)
        setShowFormDriver(true)
    }
    const openFormEditDriver = (item: itemDriver) => {
        setDriverSelected(item)
        setShowFormDriver(true)
    }
    const handleUpdateForm = () => {
        setDriverSelected(undefined)
        setShowFormDriver(false)
    }
    const handleOpenDetails = (item: itemDriver) => {
        setDriverSelected(item)
        setShowDetailsDriver(true)
    }
    const handleCloseDetails = () => {
        setDriverSelected(undefined)
        setShowDetailsDriver(false)
    }

    return (
        <>

            {isShowDetailsDriver && DriverSelected !== undefined ?
                <ViewDetailsDriver driver={DriverSelected} onCloseDetails={handleCloseDetails} />
                : <ViewListDrivers openDetails={handleOpenDetails} cadNewDriver={openFormNewDriver} cadEditDriver={openFormEditDriver} />}

            {/* {isShowFormDriver && <CadDriverForms initialData={DriverSelected} onCloseForm={() => { setShowFormDriver(false) }} onCloseFormUpdate={handleUpdateForm} />} */}
            {isShowFormDriver && <Sidebar width='700px' isOpen={isShowFormDriver} onClose={() => setShowFormDriver(false)} title={'Cadastro de Motorista'}>
                <FormDriver
                    onCancel={() => setShowFormDriver(false)}
                    onUpdate={handleUpdateForm}
                    initialData={DriverSelected}
                    uuid={uuidv4()}
                />
            </Sidebar>}
            {/*DriverSelected && <Sidebar width='700px' isOpen={isShowFormDriver} onClose={() => setShowFormDriver(false)} title={'Cadastro de Motorista'}>
                <DriverDetails
                    driver={DriverSelected}
                />
            </Sidebar>*/}
        </>
    )

}
export default ListDriversPage