import React from 'react';
import styled from 'styled-components';
import { CheckSquare, Square } from 'lucide-react';
import { iVehicleStorage } from '../../../Config/StorageRepository';

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 400px;
  overflow-y: auto;
`;

const VehicleItem = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  background: ${props => props.selected ? '#f3f4f6' : 'white'};
  color: #4b5563;
  border: 1px solid ${props => props.selected ? '#d1d5db' : '#e5e7eb'};
  
  &:hover {
    background: #f9fafb;
    border-color: #d1d5db;
  }
`;

const VehicleInfo = styled.div`
  flex: 1;
  margin-left: 1rem;
`;

const Plate = styled.div`
  font-weight: 500;
  color: #374151;
`;

const Details = styled.div`
  font-size: 0.875rem;
  color: #6b7280;
`;

interface VehicleListProps {
  vehicles: iVehicleStorage[];
  selectedIds: number[];
  onToggleSelect: (id: number) => void;
}

export const VehicleList: React.FC<VehicleListProps> = ({
  vehicles,
  selectedIds,
  onToggleSelect,
}) => {
  return (
    <List>
      {vehicles.map(vehicle => {
        const isSelected = selectedIds.includes(vehicle.id);

        return (
          <VehicleItem
            key={vehicle.id}
            selected={isSelected}
            onClick={() => onToggleSelect(vehicle.id)}
          >
            {isSelected ? (
              <CheckSquare size={20} className="text-gray-600" />
            ) : (
              <Square size={20} className="text-gray-400" />
            )}
            <VehicleInfo>
              <Plate>{vehicle.apelido}</Plate>
              <Details>
                [{vehicle.id}]  {vehicle.manufacturer} {vehicle.model} - {vehicle.placa}
              </Details>
            </VehicleInfo>
          </VehicleItem>
        );
      })}
    </List>
  );
};