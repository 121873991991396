

import React, { useRef } from 'react';
import { Container, ContainerButton, ContainerCard, ContainerCenter, ContainerFlexCenter, ContainerFlexInline, InfoItem, LabelItem, ValueAlert, ValueItem, ValueItem2 } from '../Styles/DriverStyle';
import BarTable from '../../../components/BarTable';
import { ctrlButtons, iDialogConfirme, iMsgAlert } from '../../../interfaces/interfaces';
import AlterImgDriver from '../Dialogs/AlterImgDriver';
import styled from 'styled-components';
import axios from 'axios';
import { Avatar, IconButton } from '@mui/material';
import { CameraAltOutlined, Check, Close, Delete, Edit, EditAttributes, EditOutlined, EditRoad, EditSharp, Save } from '@mui/icons-material';
import TimeAgo from 'timeago-react';
import { getDateView, isDateExpired } from '../../../utils/Converter';
import DialogConfirme from '../../../components/DialogConfirme';
import { changeAlert } from '../../../redux/alertServiceSlice';
import { itemDriver, sendDeleteDriver } from '../repository/driversRepository';

import httpCommon from '../../../services/http-common';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';

interface CompProps {
    driver: itemDriver
    onCloseDetails: () => void
}

interface ContainerProps {
    isSelected: boolean;
}


const CtrlBtnsCad: ctrlButtons = {
    CancelShow: true
}


const ImgBox = styled.img`
    margin: 0 auto;
    height: 200px;
    width: 200px;
`
interface iOptions {
    label: string
    value: string
}

const listCategory: iOptions[] = [
    { value: "1", label: "App Xdriver Completo" },
    { value: "3", label: "App Xdriver Básico" },
    { value: "4", label: "Teclado GT-601" },
]

const ContainerSelect180px = styled.div`
display: block;
margin-right: 10px;
& > select {
    text-align: left;
    width: 180px;
    border: 1px solid #b1b1b1;
}`
const ContainerLabel = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    height: 35px;
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 5px;
    padding-left: 5px;
    width: 180px;
    border: ${(props) => (props.isSelected ? '1px solid #FF7B00' : '1px solid #FF7B00')};
    color:  ${(props) => (props.isSelected ? 'white' : 'black')};;
    background-color: ${(props) => (props.isSelected ? '#FF7B00' : 'transparent')};`


const TabRegisterDriver: React.FC<CompProps> = ({ driver, onCloseDetails }) => {


    const { user } = useSelector(selectUser);
    const [isOpenDialogAvatar, setOpenDialogAvatar] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isOpenDialogConfirme, setOpenDialogConfirme] = React.useState<boolean>(false)
    const [ContentDialogConfirme, setContentDialogConfirme] = React.useState<iDialogConfirme>()
    const [imgAvatar, setImgAvatar] = React.useState<string>()
    const [idprofile, setSetIdProfile] = React.useState<string>('')
    const [isJornada, setSetIsJornada] = React.useState<number>()


    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }

    async function deleteDriver(id: number) {
        try {
            setIsLoading(true)
            const result = await sendDeleteDriver({ driverId: id });
            setIsLoading(false)
            onCloseDetails()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    }

    const validAvatar = () => {
        if (driver.DriverAvatar === null) { return }
        let imageUrl: string = `${process.env.REACT_APP_FILES_SERVER}/avatar-driver/${driver.DriverAvatar}`
        axios
            .head(imageUrl)
            .then(() => {
                setImgAvatar(imageUrl);
            })
            .catch((error) => {
                console.error('A imagem não existe:', error);
            });
    }

    React.useEffect(() => {
        validAvatar()
    }, [])

    const delDriver = () => {
        setContentDialogConfirme({
            title: "Apagar Motorista",
            msg: `Deseja apagar o motorista ${driver.DriverName}, e todos seus registros?`
        })
        setOpenDialogConfirme(true)
    }
    const activeJornada = () => {
        setContentDialogConfirme({
            title: "Apagar Motorista",
            msg: `Deseja apagar o motorista ${driver.DriverName}, e todos seus registros?`
        })
        //setOpenDialogConfirme(true)
    }
    const handleAlterarProfile = async (perfil: String) => {
        console.log(perfil, driver)
        try {
            if (!driver.isJornada) {
                let payload = {
                    IdMotorista: driver.id,
                    ativo: "1",
                    idclient: user.idcliente,
                    idperfil: Number(perfil)
                }
                const result = await httpCommon.post("setActiveMotorJornada", payload)
                driver.idperfil = Number(perfil);
                driver.isJornada = 1;
                setSetIdProfile(perfil.toString())
            } else {
                let payload = {
                    driverId: driver.id,
                    idperfil: Number(perfil)
                }
                const result = await httpCommon.post("UpProfileMotor", payload)
                driver.idperfil = Number(perfil);
                setSetIdProfile(perfil.toString())
            }


        } catch (error: unknown) {
            console.log(error)
        }
    }
    const handleDesativar = async () => {
        try {
            let payload = {
                IdMotorista: driver.id,
                ativo: "0",
                idclient: 30250809,
                idperfil: 1
            }
            const result = await httpCommon.post("setActiveMotorJornada", payload)
            driver.isJornada = 0;
            setSetIdProfile('-1')
            console.log(result)
        } catch (error: unknown) {
            console.log(error)
        }
    }
    const ConfirmDelDriver = () => {
        setOpenDialogConfirme(false)
        deleteDriver(driver.id)
    }
    const UpdateAvatar = (avatar: string) => {
        setOpenDialogAvatar(false)
        console.log(avatar)
        driver.DriverAvatar = avatar
        validAvatar()
    }

    const isShowBtnAtivar = (value: string) => {
        return value !== driver.idperfil.toString() || !driver.isJornada
    }
    const isSetSelectedJornada = (value: string): boolean => {
        return value === driver.idperfil.toString() && driver.isJornada === 1
    }



    return (
        <>
            {<AlterImgDriver open={isOpenDialogAvatar} onClose={() => setOpenDialogAvatar(false)} driver={driver} UpdateAvatar={UpdateAvatar} />}
            {<DialogConfirme open={isOpenDialogConfirme} onClose={() => setOpenDialogConfirme(false)} content={ContentDialogConfirme} onConfirme={ConfirmDelDriver} />}
            <ContainerFlexInline>
                <ContainerCard>
                    <Container>
                        <div>
                            {imgAvatar ? <ImgBox src={imgAvatar} onClick={() => setOpenDialogAvatar(true)} /> :
                                <div onClick={() => setOpenDialogAvatar(true)}>
                                    <Avatar src="/broken-image.jpg" sx={{ width: 150, height: 150 }} />

                                </div>
                            }
                        </div>
                        <ContainerCenter>
                            {/*<IconButton color="primary" aria-label="show Maps" onClick={e => setOpenDialogAvatar(true)}>
                                <Delete />
                        </IconButton>*/}
                            <IconButton color="primary" aria-label="show Maps" onClick={e => setOpenDialogAvatar(true)}>
                                <CameraAltOutlined />
                            </IconButton>
                        </ContainerCenter>
                    </Container>
                    <Container>
                        <InfoItem><LabelItem>Código:</LabelItem><ValueItem>{driver.id}</ValueItem></InfoItem>
                        <InfoItem><LabelItem>Data Aniversário:</LabelItem><ValueItem>{getDateView(driver.cnhValidate)}</ValueItem></InfoItem>
                        <InfoItem><LabelItem>Ibutton / Card:</LabelItem><ValueItem>{driver.DriverCard}</ValueItem></InfoItem>
                        <InfoItem><LabelItem>E-mail:</LabelItem><ValueItem>{driver.email}</ValueItem></InfoItem>
                        <InfoItem><LabelItem>Contato:</LabelItem><ValueItem>{driver.tel}</ValueItem></InfoItem>
                        <InfoItem><LabelItem>Endereço:</LabelItem><ValueItem>{driver.ender}</ValueItem></InfoItem>
                        <InfoItem><LabelItem>CPF:</LabelItem><ValueItem>{driver.cpf}</ValueItem></InfoItem>
                        <InfoItem><LabelItem>CNH:</LabelItem><ValueItem>{driver.numberCNH} - {driver.category}</ValueItem></InfoItem>
                        <InfoItem><LabelItem>Vencimento CNH:</LabelItem>
                            {isDateExpired(driver.cnhValidate) ? <ValueAlert>VENCIDA {getDateView(driver.cnhValidate)}</ValueAlert> :
                                <ValueItem>{getDateView(driver.cnhValidate)}</ValueItem>}
                        </InfoItem>
                        <ContainerFlexCenter>
                            {/*<ContainerButton className='color-primary'> <EditSharp /> Editar Cadastro</ContainerButton>*/}
                            <ContainerButton className='color-primary' onClick={delDriver}> <Delete />Apagar Cadastro</ContainerButton>
                        </ContainerFlexCenter>
                    </Container>

                </ContainerCard>
                <ContainerCard>
                    <Container>
                        <h3>Selecione o tipo de jornada</h3>
                        {listCategory.map((item) => (
                            <ContainerFlexInline key={item.value}>
                                <ContainerLabel isSelected={isSetSelectedJornada(item.value)}>{item.label}</ContainerLabel>
                                {isShowBtnAtivar(item.value) && (
                                    <ContainerButton className='color-primary' onClick={() => handleAlterarProfile(item.value)}> <Check />Ativar</ContainerButton>
                                )}
                            </ContainerFlexInline>
                        ))}
                        {driver.isJornada === 1 && <ContainerFlexCenter>
                            <ContainerButton className='color-primary' onClick={handleDesativar}> <Close />Desativar Jornada</ContainerButton>

                        </ContainerFlexCenter>}
                    </Container>
                </ContainerCard>
            </ContainerFlexInline>

        </>
    )

}
export default TabRegisterDriver

function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}
