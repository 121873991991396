import { styled } from "styled-components";
import { useEffect, useRef, useState } from "react";
import createMarkerShared from "./components/createMarkerShared";
import { useParams } from "react-router-dom";
import { validTokenServer, VehicleDataShared } from "./repository/sharedRepo";
import { loadMapApi } from "../../utils/GoogleMapsApi";
import { useNotifications } from "../../Notifications/NotificationContext";
import { AxiosError } from "axios";
import { extractErrorMessage } from "../../utils/CustomErros";


const ContainerMaps = styled.div`
  flex-grow: 1;
  padding: 5px;
  transition: margin-left 0.3s; /*Ajusta a margem dependendo do estado do menu */
`;

const MapContainer = styled.div`
  height: 100%; /* Garante que o mapa ocupe toda a altura do contêiner pai */
  width: 100%; /* Garante que o mapa ocupe toda a largura do contêiner pai */
  position: relative; /* Necessário para posicionar os marcadores */
`;

const LayoutContainer = styled.div`
  display: flex;
  height: 99vh;
  width: 100%;
`;

let intervalMap: any
let isInitMpp = true
let isFirstLoad = true

const SharedComponent = () => {
    const { addNotification } = useNotifications();
    const { token } = useParams();
    const [vehicles, setVehicles] = useState<VehicleDataShared[]>([])
    const markersVehicleRef = useRef<{ [id: string]: google.maps.marker.AdvancedMarkerElement }>({});
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const mapRef = useRef<google.maps.Map | null>(null);
    const mapContainerRef = useRef<HTMLDivElement>(null);


    const clearMap = () => {
        console.log('ClearMapShared');

    }

    async function getDados() {
        try {
            console.log("TokenRoute token:", token);
            if (!token) return;
            const result = await validTokenServer(token);
            let resp: VehicleDataShared[] = result;
            setVehicles(resp)
        } catch (error) {
            console.log("Error getDados", error);
            const errorMessage = extractErrorMessage(error);
            addNotification("", errorMessage, 'error')
        }

    }


    const CtrlInterval = () => {
        intervalMap = setInterval(() => {
            getDados()
        }, 15000)
    }

    useEffect(() => {
        if (isInitMpp) {
            isInitMpp = false;
            CtrlInterval()
            getDados()
        }
        //componentUnAmount()
        return () => {
            console.log("Destruct SharedMap")
            clearInterval(intervalMap)
            isInitMpp = true;
            clearMap()
        }
    }, [])


    useEffect(() => {
        const loadMap = async () => {
            try {
                const googleMaps = await loadMapApi();
                if (!mapRef.current) {
                    const mapDiv = document.createElement('div');
                    mapDiv.style.height = '100%';  // Definindo a altura
                    mapDiv.style.width = '100%';   // Definindo a largura

                    const map = new googleMaps.Map(mapDiv, {
                        center: { lat: -15.7801, lng: -47.9292 },
                        zoom: 4,
                        mapTypeControl: true,
                        streetViewControl: false,
                        rotateControl: true,
                        scaleControl: true,
                        fullscreenControl: true,
                        panControl: true,
                        zoomControl: true,
                        mapId: 'ec009120bd8c9ea',
                        mapTypeControlOptions: {
                            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                            position: google.maps.ControlPosition.BOTTOM_LEFT,
                        },
                        fullscreenControlOptions: {
                            position: google.maps.ControlPosition.TOP_RIGHT,
                        },
                        gestureHandling: 'greedy',// 'cooperative',//controla o zoom do mapa sem o CTRl
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                    });
                    mapRef.current = map;
                    mapContainerRef.current!.appendChild(map.getDiv());
                    setIsMapLoaded(true);
                }
            } catch (error) {
                console.error('Error loading Google Maps:', error);
            }
        };

        loadMap();
    }, []);

    const handleSelectVehicle = (vehicle: any) => {

    }


    useEffect(() => {
        if (mapRef.current && vehicles.length > 0) {
            const bounds = new google.maps.LatLngBounds();

            // Mapeia veículos pelo ID para facilitar comparações
            const newMarkers: { [id: string]: google.maps.marker.AdvancedMarkerElement } = {};

            // Função para atualizar markers de veículos
            const updateMarkers = () => {
                vehicles.forEach((vehicle) => {
                    const existingMarker = markersVehicleRef.current[vehicle.id];

                    if (existingMarker) {
                        // Se o veículo já existe no mapa, verificamos se a posição mudou
                        if (existingMarker.position?.lat !== Number(vehicle.gpslat) || existingMarker.position?.lng !== Number(vehicle.gpslng)) {
                            // Atualiza a posição do marker se mudou
                            existingMarker.position = new google.maps.LatLng(Number(vehicle.gpslat), Number(vehicle.gpslng));
                        }
                        // Mantemos esse marker no novo conjunto
                        newMarkers[vehicle.id] = existingMarker;
                        bounds.extend(existingMarker.position!);
                    } else {
                        // Se o veículo é novo, criamos um novo marker
                        //validar coordenadas
                        const lat = Number(vehicle.gpslat);
                        const lng = Number(vehicle.gpslng);
                        if (!isNaN(lat) && !isNaN(lng) && lat !== 0 && lng !== 0) {
                            const newMarker = createMarkerShared(mapRef.current!, vehicle, (item: any) => {
                                handleSelectVehicle(item);
                            });
                            newMarkers[vehicle.id] = newMarker;
                            bounds.extend(newMarker.position!);
                        }
                    }
                });

                // Remove markers de veículos que não estão mais presentes
                Object.keys(markersVehicleRef.current).forEach((id) => {
                    if (!newMarkers[id]) {
                        const marker = markersVehicleRef.current[id];
                        marker.map = null; // Remove o marker do mapa
                    }
                });

                // Atualiza o objeto de referência de markers com o novo estado
                markersVehicleRef.current = newMarkers;


                // Ajusta os limites do mapa para incluir todos os markers
                if (isFirstLoad) {
                    mapRef.current!.fitBounds(bounds);
                    isFirstLoad = false;
                }
            };

            updateMarkers();
        }
    }, [vehicles, isFirstLoad]);


    return (
        <LayoutContainer>
            <ContainerMaps>
                <MapContainer ref={mapContainerRef}></MapContainer>
            </ContainerMaps>
        </LayoutContainer>
    );
};


export default SharedComponent;