import React, { useState } from 'react';
import styled from 'styled-components';
import { Download, Share2, ExternalLink, Play, Image as ImageIcon, Maximize2, Link, MessageCircle } from 'lucide-react';
import { MediaFile } from '../types';
import { Modal } from './Modal';
import { formatFileSize } from '../../../utils/format';

const Card = styled.div`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  
  &:hover {
    transform: translateY(-2px);
  }
`;

const MediaContainer = styled.div`
  position: relative;
  aspect-ratio: 16/9;
  background: #f3f4f6;
  cursor: pointer;
`;

const MediaContent = styled.div`
  width: 100%;
  height: 100%;
  
  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TypeIcon = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.25rem;
  border-radius: 9999px;
`;

const Details = styled.div`
  padding: 1rem;
`;

const Filename = styled.h3`
  font-size: 0.875rem;
  font-weight: 500;
  color: #111827;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;


const RowToCard = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 0.5rem;
`;

const DateText = styled.p`
  font-size: 0.75rem;
  color: #6b7280;
`;
const SizeText = styled.p`
  font-size: 0.75rem;
  color: #6b7280;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
  
  button, a {
    padding: 0.5rem;
    color: #4b5563;
    transition: color 0.2s;
    
    &:hover {
      color: #111827;
    }
  }
`;


interface MediaCardProps {
  file: MediaFile;
  imei: string;
  onExpand: () => void;
}

export const MediaCard: React.FC<MediaCardProps> = ({ file, imei, onExpand }) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const isVideo = file.nome.endsWith('.mp4');
  const fileUrl = `${process.env.REACT_APP_FILES_RTMP}/view-file/${imei}/${file.nome}`;
  const fileUrlDownload = `${process.env.REACT_APP_FILES_RTMP}/download-video/${imei}/${file.nome}`;
  const creationDate = new Date(file.dataModificacao);

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(fileUrl)}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(fileUrl);
      alert('Link copiado para a área de transferência!');
      setShowShareModal(false);
    } catch (err) {
      console.error('Erro ao copiar link:', err);
    }
  };


  const handleDownloadFile = () => {
    window.open(fileUrlDownload, '_blank');
  }

  return (
    <Card>
      <MediaContainer onClick={onExpand}>
        <MediaContent>
          {isVideo ? (
            <video controls>
              <source src={fileUrl} type="video/mp4" />
            </video>
          ) : (
            <img src={fileUrl} alt={file.nome} />
          )}
        </MediaContent>
        <TypeIcon>
          {isVideo ? <Play size={16} /> : <ImageIcon size={16} />}
        </TypeIcon>
      </MediaContainer>

      <Details>
        <Filename>{file.nome}</Filename>
        <RowToCard>
          <DateText>{creationDate.toLocaleDateString()} {creationDate.toLocaleTimeString()}</DateText>
          <SizeText>{formatFileSize(file.tamanho)}</SizeText>
        </RowToCard>

        <Actions>
          <button onClick={onExpand} title="Expandir">
            <Maximize2 size={20} />
          </button>
          <button onClick={() => handleWhatsAppShare()} title="Compartilhar">
            <Share2 size={20} />
          </button>
          <button onClick={() => { handleDownloadFile() }} title="Baixar">
            <Download size={20} />
          </button>
        </Actions>
      </Details>
    </Card>
  );
};