import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { Tab, Box } from '@mui/material';
import TemplateList from './pages/TemplateList';
import ChecklistTab from './Views/ChecklistTab';


const ChecklistPage: React.FC = () => {

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Alertas Lista" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label="Checklists" value="1" />
                        <Tab label="Templates" value="2" />
                    </TabList>

                </Box>
                <div>
                    <TabPanel value="1"><ChecklistTab /></TabPanel>
                    <TabPanel value="2"><TemplateList /></TabPanel>
                </div>
            </TabContext>
        </>
    );
}
export default ChecklistPage