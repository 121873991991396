import React from 'react';
import styled from 'styled-components';
import { Edit2, Trash2, Link, Share2, Power } from 'lucide-react';
import { formatDistanceToNow, isPast } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { SharedLink } from '../repository/sharedRepo';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Th = styled.th`
  text-align: left;
  padding: 1rem;
  background: #374151;
  color: white;
  font-weight: 600;
`;

const Td = styled.td`
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  color: #4b5563;
`;

const ActionButton = styled.button`
  padding: 0.5rem;
  border-radius: 4px;
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
  
  &:hover {
    background: #f3f4f6;
    color: #374151;
  }
`;

const Status = styled.span<{ expired?: boolean }>`
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  ${({ expired }) => expired
    ? 'background: #fee2e2; color: #dc2626;'
    : 'background: #dcfce7; color: #16a34a;'
  }
`;

interface SharedLinkListProps {
  links: SharedLink[];
  onEdit: (link: SharedLink) => void;
  onDelete: (link: SharedLink) => void;
  onToggleActive: (link: SharedLink) => void;
  onShare: (token: string) => void;
}

export const SharedLinkList: React.FC<SharedLinkListProps> = ({
  links,
  onEdit,
  onDelete,
  onToggleActive,
  onShare,
}) => {
  return (
    <Table>
      <thead>
        <tr>
          <Th>Descrição</Th>
          <Th>Veículos</Th>
          <Th>Expira</Th>
          <Th>Status</Th>
          <Th>Ações</Th>
        </tr>
      </thead>
      <tbody>
        {links.map(link => {
          const expired = isPast(new Date(link.expires_at));
          const active = link.active;

          return (
            <tr key={link.id}>
              <Td>{link.description}</Td>
              <Td>{link.vehicle_ids.length} veículos</Td>
              <Td>
                {formatDistanceToNow(new Date(link.expires_at), {
                  addSuffix: true,
                  locale: ptBR
                })}
              </Td>
              <Td>
                <Status expired={expired || !active}>
                  {expired ? 'Expirado' : active ? 'Ativo' : 'Inativo'}
                </Status>
              </Td>
              <Td>
                <div className="flex gap-2">
                  <ActionButton onClick={() => onShare(link.token)} title="Compartilhar">
                    <Share2 size={18} />
                  </ActionButton>
                  <ActionButton onClick={() => onEdit(link)} title="Editar">
                    <Edit2 size={18} />
                  </ActionButton>
                  <ActionButton
                    onClick={() => onToggleActive(link)}
                    title={link.active ? 'Desativar' : 'Ativar'}
                  >
                    <Power size={18} />
                  </ActionButton>
                  <ActionButton onClick={() => onDelete(link)} title="Excluir">
                    <Trash2 size={18} />
                  </ActionButton>
                </div>
              </Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};