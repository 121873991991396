

import React, { useEffect, useState } from 'react';
import { Plus, Search, CheckSquare, Square, Eye, EyeOff, X, Share2, MapIcon as WhatsappIcon } from 'lucide-react';
import { SharedLinkList } from './components/SharedLinkList';
import { VehicleList } from './components/VehicleList';
import { Modal } from './components/Modal';
import { ConfirmationModal } from './components/ConfirmationModal';
import { styled } from 'styled-components';
import { SharedLink } from './repository/sharedRepo';
import * as repository from './repository/sharedRepo';
import { Input } from './components/Input';
import { StorageConfig } from '../../Config/StorageContext';
import { handleSearch } from '../../utils/searchUtils';
import { useNotifications } from '../../Notifications/NotificationContext';
import { set } from 'date-fns';
import { ShareLinkForm } from './components/ShareLinkForm';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: #f3f4f6;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const SearchContainer = styled.div`
  position: relative;
  width: 300px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.5rem 2.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  background: white;
  color: #4b5563;
  
  &::placeholder {
    color: #9ca3af;
  }
  
  &:focus {
    outline: none;
    border-color: #6b7280;
    box-shadow: 0 0 0 2px rgba(107, 114, 128, 0.2);
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #6b7280;
`;

const Button = styled.button<{ variant?: 'secondary' | 'text' }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: ${props => props.variant === 'secondary' ? '#e5e7eb' : props.variant === 'text' ? 'transparent' : '#4b5563'};
  color: ${props => props.variant === 'text' ? '#4b5563' : props.variant === 'secondary' ? '#374151' : 'white'};
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  justify-content: center;
  
  &:hover {
    background: ${props => props.variant === 'secondary' ? '#d1d5db' : props.variant === 'text' ? '#f3f4f6' : '#374151'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #374151;
  margin-bottom: 2rem;
`;

const Form = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  max-width: 700px;
  margin: auto;
`;

const FormHeader = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const VehicleFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid #374151;
`;

const FilterButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const VehicleCount = styled.div`
  background: #374151;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  color: white;
`;

const ShareModal = styled(Modal)`
  .modal-content {
    max-width: 400px;
  }
`;

const ShareButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  background: white;
  color: #4b5563;
  cursor: pointer;
  
  &:hover {
    background: #f9fafb;
  }
`;

function ShareVehiclesPage() {
    const { addNotification } = useNotifications();
    const { vehicles, syncVehicles } = StorageConfig();
    const [links, setLinks] = useState<SharedLink[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [vehicleSearchTerm, setVehicleSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [isCreating, setIsCreating] = useState(false);
    const [selectedVehicleIds, setSelectedVehicleIds] = useState<number[]>([]);
    const [newLinkDescription, setNewLinkDescription] = useState('');
    const [showSelectedOnly, setShowSelectedOnly] = useState(false);
    const [editingLink, setEditingLink] = useState<SharedLink | null>(null);
    const [interactLink, setInteractLink] = useState<SharedLink | null>(null);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [sharingLink, setSharingLink] = useState('');
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalDeleteOpen, setConfirmModalDeleteOpen] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (editingLink) {
            setNewLinkDescription(editingLink.description);
            setSelectedVehicleIds(editingLink.vehicle_ids);
            setIsCreating(true);
        }
    }, [editingLink]);

    const loadVehicle = async () => {
        if (vehicles.length === 0) {
            syncVehicles()
        }
    }

    useEffect(() => {
        if (isCreating) {
            loadVehicle();
        }
    }, [isCreating]);

    const loadData = async () => {
        try {
            setLoading(true);
            let list = await repository.getListSharedVehicles();
            console.log(list)
            setLinks(list);
        } catch (error) {
            console.error('Erro ao carregar dados:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        if (!newLinkDescription || selectedVehicleIds.length === 0) return;

        try {
            const expiresAt = new Date();
            expiresAt.setDate(expiresAt.getDate() + 7);

            if (editingLink) {
                await repository.upsertSharedLink({
                    token: editingLink.token,
                    expires_at: expiresAt.toISOString(),
                    description: newLinkDescription,
                    vehicle_ids: selectedVehicleIds,
                });
            } else {
                await repository.upsertSharedLink({
                    description: newLinkDescription,
                    vehicle_ids: selectedVehicleIds,
                    expires_at: expiresAt.toISOString(),
                    active: true
                });
            }

            await loadData();
            handleCancel();
        } catch (error) {
            console.error('Erro ao salvar link:', error);
        }
    };

    const handleCancel = () => {
        setIsCreating(false);
        setNewLinkDescription('');
        setSelectedVehicleIds([]);
        setEditingLink(null);
        setShowSelectedOnly(false);
        setVehicleSearchTerm('');
    };

    const handleDeleteLink = async (link: SharedLink) => {
        setInteractLink(link);
        setConfirmModalDeleteOpen(true);
    };

    const handleToggleActive = async (link: SharedLink) => {
        setInteractLink(link);
        setConfirmModalOpen(true);
    };

    const confirmActionActive = async () => {
        if (interactLink) {
            await repository.setActiveMode({
                token: interactLink.token,
                active: !interactLink.active
            });
            await loadData();
            setInteractLink(null);
        }
    };

    const confirmActionDelete = async () => {
        if (interactLink) {
            await repository.deleteSharedLink({
                token: interactLink.token,
                active: !interactLink.active
            });
            await loadData();
            setInteractLink(null);
        }
    }

    const handleShare = (token: string) => {
        const url = `${window.location.origin}/#/shared/${token}`;
        setSharingLink(url);
        setShareModalOpen(true);
    };

    const handleShareWhatsapp = () => {
        const url = encodeURIComponent(sharingLink);
        window.open(`https://wa.me/?text=${url}`, '_blank');
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(sharingLink);
        addNotification('', 'Link copiado para a área de transferência', 'success');
        setShareModalOpen(false);
    };

    const handleSelectAllVehicles = () => {
        const filteredVehicles = vehicles.filter(vehicle =>
            vehicle.placa.toLowerCase().includes(vehicleSearchTerm.toLowerCase()) ||
            vehicle.manufacturer.toLowerCase().includes(vehicleSearchTerm.toLowerCase()) ||
            vehicle.model.toLowerCase().includes(vehicleSearchTerm.toLowerCase()) ||
            vehicle.apelido.toString().includes(vehicleSearchTerm)
        );
        setSelectedVehicleIds(filteredVehicles.map(v => v.id));
    };

    const handleDeselectAllVehicles = () => {
        setSelectedVehicleIds([]);
    };

    const filteredLinks = React.useMemo(() => {
        return handleSearch(links, searchTerm);
    }, [searchTerm, links]);

    const filteredVehicles = React.useMemo(() => {
        let filtered = handleSearch(vehicles, vehicleSearchTerm);
        if (showSelectedOnly) {
            filtered = filtered.filter((row) => selectedVehicleIds.includes(row.id));
        }
        return filtered;
    }, [vehicleSearchTerm, vehicles, showSelectedOnly]);


    if (loading) {
        return <div className="flex items-center justify-center min-h-screen text-gray-600">Carregando...</div>;
    }

    return (
        <Container>
            <PageTitle>Gerenciamento de Links Compartilhados</PageTitle>


            {isCreating ? (
                <ShareLinkForm
                    onSave={async ({ description, vehicleIds, expiresAt }) => {
                        try {
                            if (editingLink) {
                                await repository.upsertSharedLink({
                                    token: editingLink.token,
                                    expires_at: expiresAt,
                                    description,
                                    vehicle_ids: vehicleIds,
                                });
                            } else {
                                await repository.upsertSharedLink({
                                    description,
                                    vehicle_ids: vehicleIds,
                                    expires_at: expiresAt,
                                    active: true,
                                });
                            }
                            await loadData();
                            handleCancel();
                        } catch (error) {
                            console.error('Erro ao salvar link:', error);
                        }
                    }}
                    onCancel={handleCancel}
                    editingLink={editingLink}
                    vehicles={vehicles}
                />
            ) : (
                <>
                    <Header>
                        <SearchContainer>
                            <SearchIcon>
                                <Search size={20} />
                            </SearchIcon>
                            <SearchInput
                                type="text"
                                placeholder="Pesquisar links..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </SearchContainer>
                        <Button onClick={() => setIsCreating(true)}>
                            <Plus size={20} />
                            Novo Link
                        </Button>
                    </Header>

                    <SharedLinkList
                        links={filteredLinks}
                        onEdit={setEditingLink}
                        onDelete={handleDeleteLink}
                        onToggleActive={handleToggleActive}
                        onShare={handleShare}
                    />
                </>
            )}

            <ShareModal
                isOpen={shareModalOpen}
                onClose={() => setShareModalOpen(false)}
                title="Compartilhar Link"
            >
                <div className="space-y-4">
                    <ShareButton onClick={handleShareWhatsapp}>
                        <WhatsappIcon size={20} />
                        Compartilhar no WhatsApp
                    </ShareButton>
                    <ShareButton onClick={handleCopyLink}>
                        <Share2 size={20} />
                        Copiar Link
                    </ShareButton>
                </div>
            </ShareModal>

            <ConfirmationModal
                isOpen={confirmModalOpen}
                onClose={() => setConfirmModalOpen(false)}
                onConfirm={async () => {
                    await confirmActionActive();
                    setConfirmModalOpen(false);
                }}
                title="Confirmar Ação"
                message="Tem certeza que deseja alterar o status deste link compartilhado?"
            />
            <ConfirmationModal
                isOpen={confirmModalDeleteOpen}
                onClose={() => setConfirmModalDeleteOpen(false)}
                onConfirm={async () => {
                    await confirmActionDelete();
                    setConfirmModalDeleteOpen(false);
                }}
                title="Apgar Link?"
                message="Tem certeza que deseja apagar este link?"
            />
        </Container>
    );
}

export default ShareVehiclesPage