import React, { useState, useEffect, useCallback } from 'react';
import {
  ProgressContainer,
  ProgressBar,
  Button,
  ModalText,
  ButtonGroup
} from './styles';
import { checkConnectionApi, connectWhatsappApi, disconectWhatsappApi } from '../repository/FinanceGateway';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { extractErrorMessage } from '../../../utils/CustomErros';
import { ConnectionStatus } from './WhatsAppConnection';
import { AuthConfig } from '../../../Config/AuthContext';
import QRCodeModal from './QRCodeModal';

interface ConnectionVerificationProps {
  connectionStatus: ConnectionStatus;
  onStatusChange: (status: ConnectionStatus) => void;
  onShowConfigForm: (show: boolean) => void;
}

const ConnectionVerification: React.FC<ConnectionVerificationProps> = ({
  connectionStatus,
  onStatusChange,
  onShowConfigForm
}) => {
  const { user } = AuthConfig();
  const { addNotification } = useNotifications();
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [timeUntilNextCheck, setTimeUntilNextCheck] = useState<number>(30);
  const [qrCodeImage, setQrCodeImage] = useState<string | null>(null);

  // API call to check connection status
  const checkConnection = useCallback(async (): Promise<void> => {
    setIsChecking(true);

    try {
      if (!user) {
        console.error('User not found');
        addNotification('', 'Usuário não encontrado', 'error');
        return;
      }
      const response = await checkConnectionApi(user?.idempresa);
      const { state } = response;
      switch (state) {
        case 'connected':
        case 'open':
          onStatusChange('connected');
          break;
        case 'disconnected':
          onStatusChange('disconnected');
          break;
        case 'connecting':
        case 'open':
        case 'close':
          onStatusChange('qrcode');
          break;
        default:
          console.error('Unknown connection state:', state);
          addNotification('', 'Erro desconhecido', 'error');
      }
    }
    catch (error: any) {
      console.error('Error checking connection', error);
      if (error.response.status === 404) {
        onStatusChange('disconnected');
        return;
      }
      let message = extractErrorMessage(error)
      addNotification('', message, 'error');
    } finally {
      setIsChecking(false);
      setTimeUntilNextCheck(30);
    }
  }, []);

  // API call to disconnect WhatsApp
  const disconnectWhatsApp = async (): Promise<void> => {
    setIsChecking(true);
    try {
      if (!user) {
        console.error('User not found');
        addNotification('', 'Usuário não encontrado', 'error');
        return;
      }
      const response = await disconectWhatsappApi(user?.idempresa);
      console.log('disconnectWhatsApp', response);
    }
    catch (error) {
      console.error('Error disconnectWhatsApp', error);
      let message = extractErrorMessage(error)
      addNotification('', message, 'error');
    } finally {
      setTimeout(() => checkConnection(), 2000);
      setIsChecking(false);
    }
  };

  useEffect(() => {
    // Initial connection check
    checkConnection();

    // Set up interval to check connection every 30 seconds
    const intervalId = setInterval(checkConnection, 30000);

    return () => clearInterval(intervalId);
  }, [checkConnection]);

  useEffect(() => {
    // Timer for countdown to next check
    if (!isChecking && timeUntilNextCheck > 0) {
      const timer = setInterval(() => {
        setTimeUntilNextCheck((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isChecking, timeUntilNextCheck]);

  const handleLinkWhatsApp = async () => {
    setIsChecking(true);
    try {
      if (!user) {
        console.error('User not found');
        addNotification('', 'Usuário não encontrado', 'error');
        return;
      }
      const response = await connectWhatsappApi(user?.idempresa);
      console.log('connectWhatsappApi', response);
      setQrCodeImage(response.base64);
    }
    catch (error) {
      console.error('Error connectWhatsappApi', error);
      let message = extractErrorMessage(error)
      addNotification('', message, 'error');
    } finally {
      checkConnection();
      setIsChecking(false);
    }
  };

  const handleCreatWhatsApp = async () => {
    onShowConfigForm(true);
  };

  const handleCloseQRModal = () => {
    setQrCodeImage(null);
  };

  // API call to validate QR code
  const validateQRCode = async (): Promise<void> => {
    setQrCodeImage(null);
    setTimeout(() => {
      setQrCodeImage(null);
      checkConnection();
    }, 1000);
  };

  return (
    <>
      {!isChecking && (
        <>
          <ProgressContainer>
            <ProgressBar duration={30} />
          </ProgressContainer>
          <ModalText>
            Próxima verificação em: {timeUntilNextCheck} segundos
          </ModalText>
        </>
      )}

      {isChecking && (
        <>
          <ProgressContainer>
            <ProgressBar duration={2} />
          </ProgressContainer>
          <ModalText>
            Verificando conexão...
          </ModalText>
        </>
      )}

      <ButtonGroup>
        <Button onClick={checkConnection} disabled={isChecking}>
          {isChecking ? 'Verificando...' : 'Verificar conexão agora'}
        </Button>

        {(connectionStatus === 'connected' || connectionStatus === 'qrcode') ? (
          <>
            <Button onClick={disconnectWhatsApp} disabled={isChecking}>
              Desconectar
            </Button>
            {connectionStatus === 'qrcode' &&
              <Button onClick={handleLinkWhatsApp} disabled={isChecking}>
                Vincular WhatsApp
              </Button>}
          </>
        ) : (
          <Button onClick={handleCreatWhatsApp} disabled={isChecking}>
            Cadastrar WhatsApp
          </Button>
        )}
      </ButtonGroup>
      {qrCodeImage && (
        <QRCodeModal
          qrCodeImage={qrCodeImage}
          onClose={handleCloseQRModal}
          onValidate={validateQRCode}
        />
      )}
    </>

  );
};

export default ConnectionVerification;