import React, { useState } from 'react';
import { MessageSquare } from 'lucide-react';
import {
  ConnectionCard,
  CardHeader,
  IconWrapper,
  Title,
  StatusIndicator,
  StatusText
} from './styles';
import ConfigurationForm from './ConfigurationForm';
import ConnectionVerification from './ConnectionVerification';

// types.ts
export type ConnectionStatus = 'connected' | 'disconnected' | 'qrcode';


const WhatsAppConnection: React.FC = () => {
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('disconnected');
  const [showConfigForm, setShowConfigForm] = useState<boolean>(false);

  const handleStatusChange = (status: ConnectionStatus) => {
    setConnectionStatus(status);
  };

  const handleShowConfigForm = (show: boolean) => {
    setShowConfigForm(show);
  };

  return (
    <ConnectionCard>
      <CardHeader>
        <IconWrapper>
          <MessageSquare size={20} />
        </IconWrapper>
        <Title>WhatsApp Connection</Title>
        <StatusIndicator connected={connectionStatus === 'connected'}>
          <StatusText>{connectionStatus}</StatusText>
        </StatusIndicator>
      </CardHeader>

      {showConfigForm ? (
        <ConfigurationForm
          onStatusChange={handleStatusChange}
          onFormVisibilityChange={handleShowConfigForm}
        />
      ) : (
        <ConnectionVerification
          connectionStatus={connectionStatus}
          onStatusChange={handleStatusChange}
          onShowConfigForm={handleShowConfigForm}
        />
      )}
    </ConnectionCard>
  );
};

export default WhatsAppConnection;