
import React from 'react';
import { getCheckListEntry, iChecklistEntrySelect } from '../Repository/CheckListRepo';
import { ChecklistCard } from './ChecklistCard';
import { styled } from 'styled-components';
import { AnswersList } from './AnswerList';


const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  min-height: 100vh;
  background-color: #f3f4f6;
`;

const ChecklistTab: React.FC = () => {

    const [rows, setRows] = React.useState<iChecklistEntrySelect[]>([]);
    const [checklistSelected, setChecklistSelected] = React.useState<iChecklistEntrySelect | null>(null);


    async function getDados() {
        try {
            const result = await getCheckListEntry();
            setRows(result)
        } catch (e) {
            let error = e as Error
            console.log('[ListTasksDelivery]', error)
        }
    }

    React.useEffect(() => {
        getDados()
    }, [])


    const openAnswer = (checklist: iChecklistEntrySelect) => {
        setChecklistSelected(checklist)
    }

    return (
        <Container>
            {checklistSelected != null ?
                <AnswersList entry={checklistSelected} onClose={() => setChecklistSelected(null)} />
                : <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" >
                    {rows.map((checklist) => (
                        <div onClick={() => openAnswer(checklist)}>
                            <ChecklistCard key={checklist.id} checklist={checklist} />
                        </div>
                    ))}
                </div>}
        </Container>
    )

}
export default ChecklistTab