import styled from 'styled-components';

export const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  &.primary {
    background-color: #3b82f6;
    color: white;
    border: none;
    
    &:hover {
      background-color: #2563eb;
    }
  }
  
  &.secondary {
    background-color: #e5e7eb;
    color: #374151;
    border: none;
    
    &:hover {
      background-color: #d1d5db;
    }
  }
  
  &.icon {
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
`;