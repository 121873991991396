import React, { useCallback, useState } from 'react';
import { styled } from 'styled-components';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { getCategories } from '../../ExpensesPage/repository/ExpensesRepo';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';

import { Form } from '@unform/web';
import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as yup from 'yup';
import { itemRuleAlert, itemUpAlert, UpdateAlert } from '../repository/AlertsRepo';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Slider } from '@mui/material';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';
import { Column, GridContainer, Row } from '../../StylePages';

interface CompProps {
    initialData?: itemRuleAlert
    closeForm: () => void
}

const Container = styled.div`
    display: flex;
    margin: 20px auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 750px;  
    gap: 5px;`;

const ContainerForm = styled.div`
    display: flex;
    margin: 20px auto;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    max-width: 750px;
    min-width: 350px;  
    gap: 5px;`;

const ContainerInline = styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 10px;`;



export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%; /* Ajuste conforme necessário */
  margin-top: 20px;
`;

const ValueDisplay = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
`;

interface IOption {
    value: string;
    label: string;
}


const optionsPriority: IOption[] = [
    { value: '1', label: 'Baixa' },
    { value: '2', label: 'Médio' },
    { value: '3', label: 'Alto' },
];
const optionsCercas: IOption[] = [
    { value: '1', label: 'Entrada e Saída' },
    { value: '2', label: 'Saída da Cerca' },
    { value: '3', label: 'Entrada da Cerca' },
];
const optionsAction: IOption[] = [
    { value: '0', label: 'Desativado' },
    { value: 'BLK', label: 'Bloquear ao Sair da Cerca' },
];


const FormAlertsCreate: React.FC<CompProps> = ({ initialData, closeForm }) => {
    const formRef = React.useRef<FormHandles>(null)
    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);
    const [typeAlert, setTypeAlert] = React.useState<string>(initialData?.type_alert ?? 'VEL');

    const [notifDriver, setNotifDriver] = useState(initialData?.notif_driver === 1);
    const [notifClient, setNotifClient] = useState(initialData?.notif_client === 1);
    const [pointsActive, setPointsActive] = useState(initialData?.points_active === 1);
    const [points, setPoints] = useState<number>(initialData?.points || 1);
    const [speed, setSpeed] = useState<number>(initialData?.param1 || 25);
    const [priority, setPriority] = useState<number>(initialData?.priority || 1);
    const [param1, setParam1] = useState<number | string>(initialData?.param1 || '');
    const [action, setAction] = useState<string>(initialData?.action || '');
    const [value, setValue] = useState<number>(initialData?.valor || 0);
    const [isInFavActive, setActiveInFav] = useState<boolean>(initialData?.infavactive === 1 || false);
    const [isOutFavActive, setActiveOutFav] = useState<boolean>(initialData?.outfavactive === 1 || false);






    async function getListCategories() {
        try {
            const result = await getCategories();
            let list: IOption[] = []
            for (let i = 0; i < result.length; i++) {
                list.push({ value: result[i].id, label: result[i].descr })
            }

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }



    const submitForm: SubmitHandler<iExpense> = async data => {
        try {
            formRef.current?.setErrors({})
            let itemShape: any = {
                descr: yup.string().required("Esse campo é Obrigatório"),
            };
            const schema = yup.object().shape(itemShape);
            await schema.validate(data, {
                abortEarly: false,
            });


            let newErrors: FormErrors = {};



            let out: itemUpAlert = {
                descr: data.descr,
                alerta_id: initialData?.id ?? 0,
                points: pointsActive ? points : 0,
                param1: typeAlert === 'VEL' ? speed : param1,
                notif_client: notifClient,
                notif_driver: notifDriver,
                points_active: pointsActive,
                speed: typeAlert === 'VEL' ? speed : 0,
                priority: priority,
                tipo: initialData?.type_alert ?? '',
                action: typeAlert === 'CERCA' ? action : '',
                valor: typeAlert === 'CERCA' ? value : 0
            }

            SaveObject(out)
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }


    const SaveObject = async (params: any) => {
        try {
            let res = await UpdateAlert(params)
            addNotification('', 'Despesa salva com sucesso', 'success');
            formRef.current?.reset()
            closeForm()
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }


    }



    React.useEffect(() => {
        getListCategories()
    }, [])






    const handleSliderPointsChange = (event: Event, newValue: number | number[]) => {
        setPoints(newValue as number);
    };

    const renderAlertFields = useCallback(() => {
        switch (typeAlert) {
            case "VEL":
                return (
                    <Column>
                        <SliderContainer>
                            <ValueDisplay>{speed} Km/h</ValueDisplay>
                            <Slider value={speed} max={150} min={25} onChange={(e, newValue) => setSpeed(newValue as number)} />
                        </SliderContainer>
                    </Column>
                );

            case "CERCA":
                return (
                    <>
                        <SelectFieldForm options={optionsCercas} label="Selecione o evento" name="param1" onChange={(e) => setParam1(Number(e.target.value))} />
                        <SelectFieldForm options={optionsAction} label="Selecione a ação" name="action" onChange={(e) => setAction(e.target.value)} />

                        <GridContainer>
                            <Column>
                                <FormControlLabel control={<Checkbox checked={isInFavActive} onChange={() => setActiveInFav(!isInFavActive)} />} label="Dentro da Cerca" />
                                {isInFavActive && (
                                    <Row>
                                        <InputFieldForm label="Tempo de Aviso" name="infavalert" type="number" onChange={(e) => setParam1(Number(e.target.value))} />
                                        <InputFieldForm label="Tempo de Permanência" name="infavtimer" type="number" onChange={(e) => setParam1(Number(e.target.value))} />
                                        <InputFieldForm label="Tolerância" name="infavtol" type="number" onChange={(e) => setParam1(Number(e.target.value))} />
                                    </Row>
                                )}
                            </Column>

                            <Column>
                                <FormControlLabel control={<Checkbox checked={isOutFavActive} onChange={() => setActiveOutFav(!isOutFavActive)} />} label="Fora da Cerca" />
                                {isOutFavActive && (
                                    <Row>
                                        <InputFieldForm label="Tempo de Aviso" name="outfavalert" type="number" onChange={(e) => setParam1(Number(e.target.value))} />
                                        <InputFieldForm label="Tempo de Permanência" name="outfavtimer" type="number" onChange={(e) => setParam1(Number(e.target.value))} />
                                        <InputFieldForm label="Tolerância" name="outfavtol" type="number" onChange={(e) => setParam1(Number(e.target.value))} />
                                    </Row>
                                )}
                            </Column>
                        </GridContainer>
                    </>
                );

            case "STOPMOVE":
                return <InputFieldForm label="Tempo de Parado (min)" name="param1" type="number" onChange={(e) => setParam1(Number(e.target.value))} />;

            default:
                return null;
        }
    }, [typeAlert, speed, isInFavActive, isOutFavActive]);

    return (
        <Form placeholder={""} ref={formRef} onSubmit={submitForm} noValidate={true} initialData={initialData}>
            <Container>
                <GridContainer>
                    <Column>
                        <InputFieldForm label="Descrição" name="descr" type="text" />
                        <SelectFieldForm options={optionsPriority} label="Nível" name="priority" onChange={(e) => setPriority(Number(e.target.value))} />
                    </Column>

                    <Column>
                        {renderAlertFields()}
                    </Column>
                </GridContainer>

                <FormControlLabel
                    control={<Checkbox checked={pointsActive} onChange={() => setPointsActive(!pointsActive)} />}
                    label="Ativar Infração do Motorista"
                />

                {pointsActive && (
                    <>
                        <SliderContainer>
                            <ValueDisplay>{points} pontos</ValueDisplay>
                            <Slider value={points} max={25} min={1} onChange={(e, newValue) => setPoints(newValue as number)} />
                        </SliderContainer>

                        <FormControlLabel control={<Checkbox checked={notifDriver} onChange={() => setNotifDriver(!notifDriver)} />} label="Notificar Motorista" />
                        <FormControlLabel control={<Checkbox checked={notifClient} onChange={() => setNotifClient(!notifClient)} />} label="Receber notificação" />
                    </>
                )}

                <div className="flex justify-around w-full max-w-md">
                    <button className="border-2 border-red-500 text-red-500 uppercase py-2 px-4 rounded hover:bg-red-500 hover:text-white transition-colors" onClick={closeForm}>
                        Cancelar
                    </button>
                    <button className="border-2 border-green-500 text-green-500 uppercase py-2 px-4 rounded hover:bg-green-500 hover:text-white transition-colors" type="submit">
                        Confirmar
                    </button>
                </div>
            </Container>
        </Form>
    );

}
export default FormAlertsCreate


export interface FormErrors {
    categorie?: string;
    vehicle?: string;
    driver?: string;
    fuel?: string;
    descr?: string;
    amount?: string;
    priceUnit?: string;
    qty?: string;
    kmAnt?: string;
    kmAtual?: string;
    images?: string;
}


export type iExpense = {
    unique_id: string
    id_client: number;
    id_empresa: number;
    id_motorista: number;
    id_viagem: number;
    id_veiculo: number;
    fornecedor_uuid: string | null;
    id_categ: number;
    descr: string;
    amount: number;
    expense_type: string;
    status: string;
    lat: number | null;
    lng: number | null;
    photo1: string | null;
    photo2: string | null;
    photo3: string | null;
    km_start: number | null;
    km_end: number | null;
    km_vehicle: number | null;
    km_run: number | null;
    avg_fuel: number | null;
    qty: number | null;
    price_unity: number | null;
    obs: string | null;
    fuel_cod: string | null;
    fuel_descr: string | null;
    tipo_payment_id: number | null;
    evt_at: string | null;
    sync_version: number | null;
};

