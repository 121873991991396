import React from 'react';
import { getListInvoices, itemCobrancas } from '../repository/FinanceGateway';

import 'react-data-grid/lib/styles.css';
import DataGrid, { SortColumn, SortDirection } from 'react-data-grid';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import '../../../styles/datagrid/datagrid.css'
import '../../../styles/datagrid/pagination.css'
import { getDateView } from '../../../utils/Converter';
import { DateFormatterBd, FormatterCurrency, FormatterDecimal } from '../../../utils/format';
import { selectUser } from '../../../redux/userSlice';
import { useSelector } from 'react-redux';
import { ContainerCard, ContainerDate, ContainerPicker } from '../../../components/Modal/StyleCompGlobal';


import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getInvoicesColumns } from '../Utils/InvoicesColumns';
import { handleSearch } from '../../../utils/searchUtils';
import { BoxIcon, BtnFaIcon, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, SearchAndSelectContainer } from '../../StylePages';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import NoData from '../../../components/NoData';
import { faRefresh, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import LoadingIndicator from '../../../components/LoadingIndicator';


const optionsActions = [
    { value: 'SETBLOCK', label: 'Bloquear Usuários' },
    { value: 'SETUNBLOCK', label: 'Liberar Usuários' },
    { value: 'DELETEUSER', label: 'Apagar Usuários' },

];

const CobrancasPage: React.FC = () => {


    const [rows, setRows] = React.useState<itemCobrancas[]>([]);
    const { user } = useSelector(selectUser);
    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [message, setMessage] = React.useState<string | undefined>();


    async function getDados() {
        const result = await getListInvoices({
            dtIni: '2025-03-01 00:00:00',
            dtFim: '2024-01-01 23:59:59',
            idempresa: user.idempresa
        });
        setRows(result)
    }

    React.useEffect(() => {
        getDados()
    }, [])


    const columns = getInvoicesColumns();


    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);

    const handleSelectAction = (action: string) => {
        console.log('Action:', action)
    }

    return (
        <>
            {message !== undefined ? <LoadingIndicator message={message} /> :
                <>
                    <SearchAndSelectContainer>
                        <ActionSelect
                            options={optionsActions}
                            onSelect={handleSelectAction}
                            title='Selecione a ação'
                        />
                        <ContainerSearch>
                            <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                        </ContainerSearch>
                        <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getDados} >
                            <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                        </BtnFaIcon >
                        <BtnFaIcon iconColor="darkgreen" title='Adicionar Usuário' onClick={() => { }} >
                            <BoxIcon icon={faUserAlt} className="fa-icon" /> Adicionar
                        </BtnFaIcon >
                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                    </ContainerInfo>
                    {filteredRows.length === 0 ? <NoData message="Nenhum Registro Encontrado" />
                        : (<>
                            <ContainerTable>
                                <DataGridComp
                                    rows={filteredRows}
                                    columns={columns}
                                    selectedRows={selectedRows}
                                    onRowSelect={setSelectedRows}
                                />
                            </ContainerTable>
                        </>)}

                </>
            }
        </>
    )

}
export default CobrancasPage

