import moment from "moment";
import { iUser } from "../interfaces/interfaces";
import dayjs, { Dayjs } from 'dayjs';

export function isNumber(value: any) {
    return !isNaN(parseFloat(value)) && isFinite(value)
}


export function ConverterNumber(value: any) {
    return isNumber(value) ? value : 0;
}



export function ExtractTimeOfDateTime(date: string) {
    return moment(date).format("HH:mm:ss")
}

export function getDateTimeStart() {
    let hoje = moment();
    return moment(hoje).startOf('day').format("yyyy-MM-DD HH:mm:ss")
}

export function getLast30Days() {
    return moment().subtract(30, 'days').startOf('day').format("yyyy-MM-DD HH:mm:ss")
}


export function getDateTimeEnd() {
    let hoje = moment();
    return moment(hoje).endOf('day').format("yyyy-MM-DD HH:mm:ss")
}

export function getDateToday() {
    let hoje = moment();
    return moment(hoje).endOf('day').format("yyyy-MM-DD")
}

export function getDateToBd(date: string | Date) {
    return moment(date).endOf('day').format("yyyy-MM-DD");
}


export function getDateView(date: string) {
    return moment(date).endOf('day').format("DD MMM YY");
}
export function getDateViewAgo(date: string) {
    return moment(date).endOf('day').fromNow();
}


export function isDateExpired(date: string) {
    return moment(date).isBefore(moment());
}

export function getSyncVersion(): number {
    return new Date().getTime();
}

export function converteObjLogin(userAPI: any) {
    let user: iUser = {
        iduser: userAPI.iduser,
        empresa_avatar: userAPI.empresaAvatar,
        fantasia: userAPI.fantasia,
        host: '', // Valor padrão, já que não existe no segundo objeto
        email: userAPI.email,
        idcliente: userAPI.idclient,
        idempresa: userAPI.idempresa,
        idgroup: userAPI.idgroup,
        login: userAPI.login,
        name: userAPI.name,
        nivel: userAPI.nivel,
        phone: userAPI.phone,
        photo: userAPI.photo,
        isAuthenticated: false, // Valor padrão, já que não existe no segundo objeto
        token: userAPI.token ?? '' // Usa um valor vazio se token for undefined
    };
    return user
}

function isDayjs(date: any): date is Dayjs {
    return dayjs.isDayjs(date);
}

export function formatDateToBd(date: Date): string {
    const validDate = date instanceof Date && !isNaN(date.getTime()) ? date : new Date();
    const year = validDate.getFullYear();
    const month = ('0' + (validDate.getMonth() + 1)).slice(-2);
    const day = ('0' + validDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
};

export function formatDateDayJs(date: Dayjs): string {
    const validDate = isDayjs(date) ? date : dayjs();
    return `${validDate.year()}-${('0' + (validDate.month() + 1)).slice(-2)}-${('0' + validDate.date()).slice(-2)} ${('0' + validDate.hour()).slice(-2)}:${('0' + validDate.minute()).slice(-2)}:${('0' + validDate.second()).slice(-2)}`;
};

export function formatDateToScreenDayJs(date: Dayjs): string {
    const validDate = isDayjs(date) ? date : dayjs();
    return `${('0' + validDate.date()).slice(-2)}/${('0' + (validDate.month() + 1)).slice(-2)}/${validDate.year()} ${('0' + validDate.hour()).slice(-2)}:${('0' + validDate.minute()).slice(-2)}:${('0' + validDate.second()).slice(-2)}`;
}
export function formatDateToScreenString(dt: string): string {
    const validDate = dayjs(dt);
    return isDayjs(validDate) ?
        `${('0' + validDate.date()).slice(-2)}/${('0' + (validDate.month() + 1)).slice(-2)}/${validDate.year()} ${('0' + validDate.hour()).slice(-2)}:${('0' + validDate.minute()).slice(-2)}:${('0' + validDate.second()).slice(-2)}`
        : `Data inválida ${dt}`;

}

export const isValidDate = (date: Dayjs) => {
    return date && dayjs(date, 'DD/MM/YYYY', true).isValid();
};


export function isValidHexColor(color: string): boolean {
    const hexColorRegex = /^#([0-9A-Fa-f]{6})$/;
    return hexColorRegex.test(color);
}


export function dtExpireInDays(days: number): Dayjs {
    return dayjs().add(days, 'days')
}