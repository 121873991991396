import React, { useEffect, useState } from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalTitle,
  ModalText,
  QRCodeContainer,
  ProgressContainer,
  ProgressBar,
  Button,
  ButtonGroup
} from './styles';

interface QRCodeModalProps {
  qrCodeImage: string;
  onClose: () => void;
  onValidate: () => void;
}

const QRCodeModal: React.FC<QRCodeModalProps> = ({ qrCodeImage, onClose, onValidate }) => {
  const [timeLeft, setTimeLeft] = useState<number>(30);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          // Auto-close when timer reaches zero
          onClose();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [onClose]);

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Escaneie o código QR</ModalTitle>
        <ModalText>
          Abra o WhatsApp no seu telefone e escaneie o código QR abaixo para conectar.
        </ModalText>

        <QRCodeContainer>
          <img src={qrCodeImage} alt="QR Code para conexão do WhatsApp" />
        </QRCodeContainer>

        <ProgressContainer>
          <ProgressBar duration={30} />
        </ProgressContainer>

        <ModalText>
          Tempo restante: {timeLeft} segundos
        </ModalText>

        <ButtonGroup>
          <Button onClick={onClose}>Cancelar</Button>
          <Button onClick={onValidate}>Já escaneei o código</Button>
        </ButtonGroup>
      </ModalContent>
    </ModalOverlay>
  );
};

export default QRCodeModal;