import React, { useState } from 'react';
import styled from 'styled-components';
import { DayPicker } from 'react-day-picker';
import { ptBR } from 'date-fns/locale';
import { Filter } from 'lucide-react';
import 'react-day-picker/dist/style.css';

const FilterContainer = styled.div`
  position: relative;
`;

const FilterButton = styled.button`
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.2s;
  color: #374151;

  &:hover {
    border-color: #9ca3af;
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background: white;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 50;
  padding: 1.5rem;
  width: 360px;
`;

const FilterSection = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e5e7eb;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.5rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  background-color: white;
  color: #374151;
  font-size: 0.875rem;

  &:focus {
    outline: none;
    border-color: #3b82f6;
    ring: 2px solid rgba(59, 130, 246, 0.5);
  }
`;

const CalendarStyles = styled.div`
  .rdp {
    --rdp-cell-size: 35px;
    --rdp-accent-color: #3b82f6;
    --rdp-background-color: #e5e7eb;
    margin: 0;
  }

  .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
    background-color: var(--rdp-accent-color);
    color: white;
  }

  .rdp-day_selected:hover:not(.rdp-day_disabled):not(.rdp-day_outside) {
    background-color: #2563eb;
  }
`;

interface FilterDropdownProps {
    onFiltersChange: (filters: { limit: number; dateBegin?: Date }) => void;
    selectedDate?: Date;
    selectedLimit: number;
}

const limitOptions = [10, 20, 50, 100, 200, 300, 500, 750, 1000];

export const FilterDropdown: React.FC<FilterDropdownProps> = ({
    onFiltersChange,
    selectedDate,
    selectedLimit
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleDaySelect = (date: Date | undefined) => {
        onFiltersChange({ limit: selectedLimit, dateBegin: date });
        setIsOpen(false)
    };

    const handleLimitChange = (limit: number) => {
        onFiltersChange({ limit, dateBegin: selectedDate });
        setIsOpen(false)
    };

    return (
        <FilterContainer>
            <FilterButton onClick={() => setIsOpen(!isOpen)}>
                <Filter size={20} color="#6b7280" />
            </FilterButton>

            {isOpen && (
                <DropdownContainer>
                    <FilterSection>
                        <Label>Quantidade de arquivos:</Label>
                        <Select
                            value={selectedLimit}
                            onChange={(e) => handleLimitChange(Number(e.target.value))}
                        >
                            {limitOptions.map((limit) => (
                                <option key={limit} value={limit}>
                                    {limit} arquivos
                                </option>
                            ))}
                        </Select>
                    </FilterSection>

                    <FilterSection>
                        <Label>Exibir arquivos anteriores a:</Label>
                        <CalendarStyles>
                            <DayPicker
                                mode="single"
                                selected={selectedDate}
                                onSelect={handleDaySelect}
                                locale={ptBR}
                            />
                        </CalendarStyles>
                    </FilterSection>
                </DropdownContainer>
            )}
        </FilterContainer>
    );
};