import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FileDown, AlertCircle, Server, Laptop, XCircle } from 'lucide-react';

interface LoadingPrintModalProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const progressAnimation = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const dataTransfer = keyframes`
  0% { transform: translateX(0); opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { transform: translateX(200px); opacity: 0; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.3s ease-in-out;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  color: #4b5563;
  position: relative;
`;

const ProgressBarContainer = styled.div`
  background-color: #f3f4f6;
  border-radius: 10px;
  height: 10px;
  margin: 1.5rem 0;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ warning?: boolean }>`
  height: 100%;
  background-color: ${props => props.warning ? '#ef4444' : '#4b5563'};
  animation: ${progressAnimation} 60s linear;
  transition: background-color 0.3s ease;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  animation: ${pulse} 2s infinite ease-in-out;
`;

const Message = styled.p<{ warning?: boolean }>`
  color: ${props => props.warning ? '#ef4444' : '#4b5563'};
  text-align: center;
  margin: 1rem 0;
  font-size: 1rem;
  line-height: 1.5;
`;

const Title = styled.h2`
  text-align: center;
  color: #4b5563;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const Timer = styled.div`
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  color: #4b5563;
  margin: 1rem 0;
  font-family: monospace;
`;

const TransferContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto;
  width: 300px;
`;

const DataPoint = styled.div`
  width: 8px;
  height: 8px;
  background-color: #4b5563;
  border-radius: 50%;
  position: absolute;
  animation: ${dataTransfer} 2s infinite;

  &:nth-child(2) { animation-delay: 0.4s; }
  &:nth-child(3) { animation-delay: 0.8s; }
`;

const CancelButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4b5563;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(75, 85, 99, 0.1);
  }

  span {
    font-size: 0.875rem;
  }
`;

export const LoadingPrintModal: React.FC<LoadingPrintModalProps> = ({ isOpen, onClose, title }) => {
    const [progress, setProgress] = useState(0);
    const [showWarning, setShowWarning] = useState(false);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        if (!isOpen) {
            setProgress(0);
            setShowWarning(false);
            setTimer(0);
            return;
        }

        const progressInterval = setInterval(() => {
            setProgress(prev => {
                if (prev >= 100) {
                    clearInterval(progressInterval);
                    return 100;
                }
                return prev + 1;
            });
        }, 600); // 60 seconds total duration

        const timerInterval = setInterval(() => {
            setTimer(prev => prev + 1);
        }, 1000);

        const warningTimeout = setTimeout(() => {
            setShowWarning(true);
        }, 30000); // Show warning after 30 seconds

        return () => {
            clearInterval(progressInterval);
            clearInterval(timerInterval);
            clearTimeout(warningTimeout);
        };
    }, [isOpen]);

    if (!isOpen) return null;

    const formatTime = (seconds: number) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <ModalOverlay>
            <ModalContent>
                <CancelButton onClick={onClose}>
                    <XCircle size={20} />
                    <span>Cancelar</span>
                </CancelButton>

                <IconContainer>
                    <FileDown size={48} color="#4b5563" />
                </IconContainer>

                <Title>
                    {title || 'Gerando PDF'}
                </Title>

                <Timer>{formatTime(timer)}</Timer>

                <TransferContainer>
                    <Server size={24} color="#4b5563" />
                    <DataPoint />
                    <DataPoint />
                    <DataPoint />
                    <Laptop size={24} color="#4b5563" />
                </TransferContainer>

                <ProgressBarContainer>
                    <ProgressBar warning={showWarning} />
                </ProgressBarContainer>

                <Message>
                    Aguarde enquanto geramos seu arquivo.
                    O tempo de processamento pode variar dependendo do tamanho do arquivo.
                </Message>

                {showWarning && (
                    <Message warning>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem' }}>
                            <AlertCircle size={20} />
                            <span>
                                Desculpe pela demora! Recomendamos ajustar os parâmetros de filtro para melhorar o tempo de processamento.
                            </span>
                        </div>
                    </Message>
                )}
            </ModalContent>
        </ModalOverlay>
    );
};