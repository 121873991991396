import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { Tab, Box, IconButton } from '@mui/material';
import TabRegisterDriver from '../Components/TabRegisterDriver';
import { ContainerBar, ContainerCenter, ValueItem2 } from '../Styles/DriverStyle';
import { Close } from '@mui/icons-material';
import { itemDriver } from '../repository/driversRepository';


interface CompProps {
    driver: itemDriver
    onCloseDetails: () => void
}


const ViewDetailsDriver: React.FC<CompProps> = ({ driver, onCloseDetails }) => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <ContainerBar>
                <ContainerCenter>
                    <IconButton color="primary" aria-label="show Maps" onClick={() => onCloseDetails()}>
                        <Close />
                    </IconButton>
                    <ValueItem2>{driver.DriverName}</ValueItem2>
                </ContainerCenter>
            </ContainerBar>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label="Cadastro" value="1" />
                        {/*<Tab label="Jornada" value="2" />
                        <Tab label="Eventos" value="3" />
                        <Tab label="Infrações" value="4" />*/}
                    </TabList>
                </Box>
                <div>
                    <TabPanel value="1"><TabRegisterDriver driver={driver} onCloseDetails={() => onCloseDetails()} /></TabPanel>
                </div>
            </TabContext>
        </>
    );

}
export default ViewDetailsDriver