
import React, { useEffect, useMemo, useState } from 'react';
import { downLoadFile, downLoadFileInGallery, getFilesInServerGallery, getVehiclesByStream, IFilesVideo } from '../repository/StreamRepo';
import { useSelector } from 'react-redux';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { selectUser } from '../../../redux/userSlice';

import { Filter, Grid2X2, List, RefreshCw } from 'lucide-react';
import { VehicleSelect } from '../Components/VehicleSelect';
import { MediaCard } from '../Components/MediaCard';
import { MediaTable } from '../Components/MediaTable';
import { Modal } from '../Components/Modal';
import { ViewMode, MediaType, GridConfig, MediaFile, Vehicle } from '../types';
import { styled } from 'styled-components';
import { FilterDropdown } from '../Components/FilterDropdown';
import { ActiveFilters } from '../Components/ActiveFilters';


const Container = styled.div`
  min-height: 100vh;
  background-color: #f3f4f6;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem;
  
  @media (min-width: 768px) {
    padding: 1.5rem;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 10px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  
  @media (min-width: 640px) {
    gap: 1rem;
  }
`;

const Select = styled.select`
  padding: 0.5rem 2rem 0.5rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  background-color: white;
  color: #374151;
  cursor: pointer;
  
  &:focus {
    outline: none;
    border-color: #4b5563;
    box-shadow: 0 0 0 2px rgba(75, 85, 99, 0.2);
  }
`;

const IconButton = styled.button<{ $active?: boolean }>`
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: ${props => props.$active ? '#111827' : '#4b5563'};
  background: ${props => props.$active ? '#f3f4f6' : 'transparent'};
  
  &:hover {
    background: #f3f4f6;
  }
`;

const Grid = styled.div<{ config: GridConfig }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${props => props.config.minWidth}px, 1fr));
  gap: 16px;
  width: 100%;
`;

const MediaPreview = styled.div`
  max-width: 90vw;
  max-height: 90vh;
  
  img, video {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
`;


interface Option {
    value: string;
    label: string;
}

const DashCamGallery: React.FC = () => {
    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);

    const [vehicles, setVehicles] = React.useState<Vehicle[]>([]);
    const [listMedias, setListMedias] = React.useState<MediaFile[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [fileLimit, setFileLimit] = useState(50);

    const [showShareModal, setShowShareModal] = useState(false);

    const [viewMode, setViewMode] = useState<ViewMode>('grid');
    const [mediaType, setMediaType] = useState<MediaType>('all');
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
    const [selectedMedia, setSelectedMedia] = useState<MediaFile | null>(null);
    const [gridConfig, setGridConfig] = useState<GridConfig>({
        minWidth: 300
    });

    const filteredMedia = useMemo(() => {
        let filtered = [...listMedias];

        if (mediaType === 'images') {
            filtered = filtered.filter(file => file.nome.match(/\.(jpg|jpeg|png|gif)$/i));
        } else if (mediaType === 'videos') {
            filtered = filtered.filter(file => file.nome.endsWith('.mp4'));
        }

        return filtered;
    }, [mediaType, listMedias]);



    async function getVehicles() {
        const payload = {
            idcliente: user.idcliente,
            idempresa: user.idempresa,
            nivel: user.nivel
        }
        const result = await getVehiclesByStream(payload);
        setVehicles(result)
        setSelectedVehicle(result[0])
    }


    async function getFiles() {
        if (selectedVehicle === undefined) {
            addNotification('', 'Selecione um veículo', 'error');
            return
        }
        // const result = typeSelected === 'JC4xx' ?
        //     await getFilesInServerGallery(selectedVehicle, 100) :
        //     await getFilesInServer(selectedVehicle);

        const timestamp = selectedDate ? selectedDate?.getTime() : new Date().getTime();
        const result = await getFilesInServerGallery(selectedVehicle.imei, fileLimit, timestamp);
        setListMedias(result.filesDetails)
    }


    useEffect(() => {
        getVehicles()
    }, [])


    async function downloadFile(row: IFilesVideo) {
        console.log(row)
        if (selectedVehicle === undefined) {
            addNotification('', 'Selecione um veículo', 'error');
            return
        }

        let url = selectedVehicle.tipo === 'JC4xx' ? downLoadFileInGallery(selectedVehicle.imei, row.nome) : downLoadFile(selectedVehicle.imei, row.nome);
        addNotification(
            'Download Vídeo',
            'Download do vídeo em andamento',
            'success'
        )
        window.open(url, '_blank')
    }


    const handleRefresh = () => {
        getFiles()
    };


    // const handleWhatsAppShare = () => {
    //     const fileUrl = `${process.env.REACT_APP_FILES_RTMP}/view-file/${imei}/${file.nome}`;
    //     const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(fileUrl)}`;
    //     window.open(whatsappUrl, '_blank');
    //     setShowShareModal(false);
    // };


    // const handleShare = (file: string) => {
    //     setSelectedMedia(file);
    //     setShowShareModal(true);
    // }


    const handleFiltersChange = ({ limit, dateBegin }: { limit: number; dateBegin?: Date }) => {
        setFileLimit(limit);
        setSelectedDate(dateBegin);
    };

    useEffect(() => {
        if (selectedVehicle === undefined) return
        getFiles()
    }, [selectedDate, fileLimit, selectedVehicle])

    const handleSettingsChange = (minWidth: number) => {
        setGridConfig({ minWidth });
    }

    const handleClearDate = () => {
        handleFiltersChange({ limit: fileLimit, dateBegin: undefined });
    };

    const handleClearLimit = () => {
        handleFiltersChange({ limit: 20, dateBegin: selectedDate });
    };

    return (
        <Container>
            <Content>
                <Header>
                    <VehicleSelect
                        vehicles={vehicles}
                        onVehicleSelect={setSelectedVehicle}
                    />


                    <Controls>
                        <Select
                            value={mediaType}
                            onChange={(e) => setMediaType(e.target.value as MediaType)}
                        >
                            <option value="all">Todos</option>
                            <option value="images">Imagens</option>
                            <option value="videos">Vídeos</option>
                        </Select>
                        <Select
                            value={gridConfig.minWidth}
                            onChange={(e) => handleSettingsChange(Number(e.target.value))}
                        >
                            <option value="200">200px</option>
                            <option value="300">300px</option>
                            <option value="500">500px</option>
                        </Select>
                        <FilterDropdown
                            selectedDate={selectedDate}
                            selectedLimit={fileLimit}
                            onFiltersChange={handleFiltersChange}
                        />
                        <IconButton
                            onClick={() => setViewMode(viewMode === 'grid' ? 'table' : 'grid')}
                            title={viewMode === 'grid' ? 'Visualização em Tabela' : 'Visualização em Grid'}
                            $active={false}
                        >
                            {viewMode === 'grid' ? <List size={20} /> : <Grid2X2 size={20} />}
                        </IconButton>

                        <IconButton
                            onClick={handleRefresh}
                            title="Atualizar"
                        >
                            <RefreshCw size={20} />
                        </IconButton>
                    </Controls>
                </Header>
                <Filters>
                    <ActiveFilters
                        date={selectedDate}
                        limit={fileLimit}
                        totalFiles={filteredMedia.length}
                        onClearDate={handleClearDate}
                        onClearLimit={handleClearLimit}
                    />
                </Filters>


                {selectedVehicle && <div>{viewMode === 'grid' ? (
                    <Grid config={gridConfig}>
                        {filteredMedia.map((file) => (
                            <MediaCard
                                key={file.nome}
                                file={file}
                                imei={selectedVehicle.imei}
                                onExpand={() => setSelectedMedia(file)}
                            />
                        ))}
                    </Grid>
                ) : (
                    <MediaTable
                        files={filteredMedia}
                        imei={selectedVehicle.imei}
                        onExpand={setSelectedMedia}
                    />
                )}
                </div>}
            </Content>

            <Modal
                isOpen={!!selectedMedia}
                onClose={() => setSelectedMedia(null)}
            >
                {(selectedMedia && selectedVehicle) && (
                    <MediaPreview>
                        {selectedMedia.nome.endsWith('.mp4') ? (
                            <video controls>
                                <source
                                    src={`${process.env.REACT_APP_FILES_RTMP}/view-file/${selectedVehicle.imei}/${selectedMedia.nome}`}
                                    type="video/mp4"
                                />
                            </video>
                        ) : (
                            <img
                                src={`${process.env.REACT_APP_FILES_RTMP}/view-file/${selectedVehicle.imei}/${selectedMedia.nome}`}
                                alt={selectedMedia.nome}
                            />
                        )}
                        <p>{selectedMedia.nome}</p>
                    </MediaPreview>
                )}
            </Modal>
            {/* <Modal
                isOpen={showShareModal}
                onClose={() => setShowShareModal(false)}
            >
                <ShareModal>
                    <ShareTitle>Compartilhar</ShareTitle>
                    <ShareOptions>
                        <ShareButton onClick={handleWhatsAppShare}>
                            <MessageCircle size={20} />
                            Compartilhar no WhatsApp
                        </ShareButton>
                        <ShareButton onClick={handleCopyLink}>
                            <Link size={20} />
                            Copiar Link
                        </ShareButton>
                    </ShareOptions>
                </ShareModal>
            </Modal> */}
        </Container>
    );

}
export default DashCamGallery




const ShareModal = styled.div`
  padding: 1.5rem;
  min-width: 300px;
`;

const ShareTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1.5rem;
`;

const ShareOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ShareButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  background: #f3f4f6;
  color: #111827;
  font-weight: 500;
  transition: all 0.2s;
  
  &:hover {
    background: #e5e7eb;
  }
`;