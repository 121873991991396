import React from 'react';
import { Modal } from './Modal';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

const Button = styled.button<{ variant?: 'danger' | 'secondary' }>`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  
  ${({ variant }) => 
    variant === 'danger' 
      ? 'background: #dc2626; color: white; border: none;'
      : variant === 'secondary'
      ? 'background: #e5e7eb; color: #374151; border: none;'
      : 'background: #4b5563; color: white; border: none;'
  }
  
  &:hover {
    opacity: 0.9;
  }
`;

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <p className="text-gray-600">{message}</p>
      <ButtonContainer>
        <Button variant="secondary" onClick={onClose}>Cancelar</Button>
        <Button variant="danger" onClick={onConfirm}>Confirmar</Button>
      </ButtonContainer>
    </Modal>
  );
};