import { httpAxiosV4 } from "../../../gateways/Axios";
import httpApiV3 from "../../../services/http-xsmart-apiv3";


export async function getListDrivers(): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `drivers`,
        "GET"
    );
    return response;
}



export async function sendDeleteDriver(payload: any): Promise<any> {
    const result = await httpApiV3.post("/delete-driver", payload);
    return result.data;
}


export async function sendEditDriver(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `edit-driver`,
        "POST",
        payload
    );
    return response;
}


export async function sendCreateDriver(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `create-driver`,
        "POST",
        payload
    );
    return response;
}



export async function updatePassword(iduser: number, password: string): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `user/updatepassword`,
        "POST",
        { iduser, password }
    );
    return response;
}


export async function getListDriversV4(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `drivers`,
        "GET"
    );
    return data;
}

/*export async function getListDriversV4(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `drivers`,
        "GET"
    );
    return data;
}*/


export type itemDriver = {
    id: number;
    DriverAvatar: string;
    DriverName: string;
    DriverCard: string;
    cpf: string;
    category: string;
    numberCNH: string;
    dtniver: string;         // ou Date, dependendo do uso
    cnhValidate: string;     // ou Date
    dtCad: string;           // ou DateTime
    ender: string;
    tel: string;
    email: string;
    isJornada: number;       // poderia ser boolean (0 | 1)
    idperfil: number;
    profileDescr: string;
    login: string | null;
    idLogin: number | null;
};


// export type itemDriver = {
//     DriverAvatar: string
//     DriverCard: string
//     DriverName: string
//     address: string
//     SelCar: number
//     category: string
//     cnhValidate: string
//     cpf: string
//     dtCad: string
//     dtniver: string
//     email: string
//     ender: string
//     id: number
//     idperfil: number
//     isJornada: number
//     profileDescr: string
//     numberCNH: string
//     tel: string
//     veiculoId: number
// }

export type itemPayloadCreateDriver = {
    DriverCard: string
    DriverName: string
    category: string
    cnhValidate: string
    cpf: string
    dtniver: string
    email: string
    numberCNH: string
    tel: string
    ender: string
    login: string
    password: string
}

export type itemPayloadEditDriver = {
    DriverCard: string
    DriverName: string
    category: string
    cnhValidate: string
    cpf: string
    dtniver: string
    email: string
    id: number
    numberCNH: string
    tel: string
    ender: string
    login: string
    idLogin: number
    prev_login: string
    password?: string
}