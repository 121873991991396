import React from 'react';
import styled from 'styled-components';
import { AnswerCard } from './AnswerCard';
import { getCheckListAnswer, getReportChecklist, iChecklistAnswer, iChecklistEntrySelect } from '../Repository/CheckListRepo';
import { ArrowLeft, Printer } from 'lucide-react';
import { openReportInNewAba } from '../../Repoter/repository/ReportRepo';

const Container = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
`;
const Header = styled.div`
  margin-bottom: 2rem;
`;

const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  color: #4b5563;
  transition: all 0.2s;
  
  &:hover {
    background-color: #f3f4f6;
    color: #1f2937;
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
`;

const Subtitle = styled.p`
  color: #6b7280;
  font-size: 0.875rem;
`;

interface AnswersListProps {
  entry: iChecklistEntrySelect;
  onClose: () => void;
}

export const AnswersList: React.FC<AnswersListProps> = ({
  entry, onClose
}) => {

  const title = `Respostas do checklist ${entry.template_descr}`;
  const [answers, setRows] = React.useState<iChecklistAnswer[]>([]);

  async function getDados() {
    try {
      const result = await getCheckListAnswer(entry.uuid);
      setRows(result)
    } catch (e) {
      let error = e as Error
      console.log('[getCheckListAnswer]', error)
    }
  }

  React.useEffect(() => {
    getDados()
  }, [])


  const onPrint = async () => {
    const result = await getReportChecklist(entry.uuid);
    openReportInNewAba(result);
  }

  return (
    <Container>
      <Header>
        <HeaderTop>
          <BackButton onClick={onClose}>
            <ArrowLeft size={20} />
            <span>Voltar</span>
          </BackButton>
          <Title>{title}</Title>
          <BackButton onClick={onPrint}>
            <Printer size={20} />
            <span>Imprimir</span>
          </BackButton>
        </HeaderTop>
        <Subtitle>{answers.length} respostas encontradas</Subtitle>

      </Header>

      {answers.map((answer) => (
        <AnswerCard key={answer.answer_uuid} answer={answer} />
      ))}
    </Container>
  );
};