export const handleSearch = (rows: any[], searchText: string) => {
    if (rows.length === 0) return rows;
    const normalizedSearchText = searchText
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

    // Filtra os veículos com base no texto de busca
    const filtered = rows.filter((vehicle: any) => {
        const matchesSearchText = Object.values(vehicle).some((value) => {
            const normalizedValue = String(value)
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();
            return normalizedValue.includes(normalizedSearchText);
        });
        return matchesSearchText;
    });

    return filtered;
};
